<template>
  <td
    :style="{ color: getColor, fontWeight: 600, cursor: 'pointer' }"
    @click="goTo"
    class="clicked"
  >
    {{ value }}
  </td>
</template>

<script>
import {URL_OVERVIEW_COLORS} from "@/utils/defaultData";
import QueryBuilder from "@/mixins/QueryBuilder";

export default {
  props: ["value", "data", "needTop"],
  mixins: [QueryBuilder],
  computed: {
    getColor() {
      const { green, yellow, red } = URL_OVERVIEW_COLORS;
      if (this.value === 100) {
        return green;
      }
      if (this.value >= 75 && this.value <= 99) {
        return yellow;
      }
      if (this.value >= 0 && this.value <= 74) {
        return red;
      }

      return "transparent";
    },
  },
  methods: {
    goTo() {
      const url = "/site-workplace/dashboard";
      const params = {
        need_top: this.needTop,
        project_id: this.data.id,
      };
      window.open(url + this._$buildQuery(params), "_blank");
    },
  },
};
</script>
