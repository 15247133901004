<template>
  <div :class="`time-chart ${is_dark_mode ? 'dark-mode' : ''}`">
    <ApexCharts
      height="275"
      :options="get_chart_options"
      :series="series"
      type="line"
    >
    </ApexCharts>
  </div>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: { ApexCharts },
  props: {
    series: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: null,
    },
    footerTitle: {
      type: String,
      default: null,
    },
  },
  computed: {
    get_chart_options() {
      return {
        legend: {
          position: "right",
        },
        chart: {
          height: 300,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        title: {
          text: this.title,
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return this.formatNumber(val);
            },
          },
        },
        xaxis: {
          type: this.footerTitle === "Day" ? "datetime" : null,
          categories: this.categories,
          title: { text: this.footerTitle },
        },
      };
    },
    is_dark_mode() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    formatNumber(number) {
      if (isNaN(number)) return 0;

      const suffixes = ["", "k", "m", "b", "t"];

      const sign = Math.sign(number);
      const absNumber = Math.abs(number);

      const suffixIndex = Math.floor(Math.log10(absNumber) / 3);

      const formattedNumber = (absNumber / Math.pow(1000, suffixIndex)).toFixed(
        1
      );

      if (sign === 0) return 0;

      return sign * formattedNumber + suffixes[suffixIndex];
    },
  },
};
</script>

<style lang="scss">
.time-chart {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px 5px 15px;
  margin-right: 0;
  &.dark-mode {
    background-color: #1e1e1e;
    .apexcharts-text {
      fill: #fff;
    }
    .apexcharts-grid-row {
      fill: #2a2a2a;
    }
    .apexcharts-tooltip {
      color: #000;
    }
    .apexcharts-menu {
      background-color: #393939;
      color: #e8e8e8;
      border-color: #232323;
      .apexcharts-menu-item {
        &:hover {
          background-color: #646464;
        }
      }
    }
  }
  .apexcharts-xaxis-title-text {
    font-size: 14px;
    font-weight: 400;
  }
  .apexcharts-title-text {
    fill: #9d9d9d;
    font-size: 18px;
    font-weight: 400;
  }
}
</style>
