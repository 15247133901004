<template>
  <expansion-block max-height="600px">
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      hide-default-footer
    >
      <template #body="{ items }">
        <tbody>
          <tr v-for="(row, index) in items" :key="index">
            <td>{{ row.id }} / {{ row.autoincrement_id }}</td>
            <td>{{ row.parser }}</td>
            <td>{{ row.queries_all }} / {{ row.failed_queries }}</td>
            <td>{{ row.status_aparser }}</td>
            <td>{{ row.status_laravel }}</td>
            <td class="text-center">
              <v-icon
                v-if="row.error !== null"
                color="error"
                @click="handleOpenErrorDetails(row)"
                >mdi-alert</v-icon
              >
              <div v-else class="green--text">none</div>
            </td>
            <td>{{ row.created_at }}</td>
            <td class="text-center">
              <v-menu
                nudge-left="25"
                left
                :close-on-content-click="false"
                rounded="lg"
              >
                <template #activator="{ on }">
                  <v-icon v-on="on"> mdi-server </v-icon>
                </template>
                <v-list dense nav>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-tag-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ row.server.name }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-link</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      <a
                        :href="row.server.api_url"
                        target="_blank"
                        style="text-decoration: none"
                      >
                        {{ row.server.api_url }}
                      </a>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-lock-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ row.server.password }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td>
              <v-menu nudge-left="25" left rounded="lg">
                <template #activator="{ on }">
                  <v-icon v-on="on"> mdi-dots-vertical </v-icon>
                </template>
                <v-list dense nav>
                  <v-list-item @click="requestAction(row, 'resend')">
                    <v-list-item-icon>
                      <v-icon>mdi-email-sync-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Resend </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="requestAction(row, 'reinsert')">
                    <v-list-item-icon>
                      <v-icon>mdi-cached</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Reinsert </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="requestAction(row, 'delete')"
                    class="error--text"
                  >
                    <v-list-item-icon>
                      <v-icon color="error">mdi-trash-can-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Delete </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-dialog
      v-model="aparserErrorInfoDialog"
      content-class="remove-dialog-shadow"
      max-width="450"
    >
      <v-card rounded="lg">
        <v-card-title> Error details </v-card-title>
        <v-card-text>{{ aparserErrorInfoData }}</v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="aparserErrorInfoDialog = false" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </expansion-block>
</template>

<script>
import { DEV_DASHBOARD_APARSER_DETAILS_TABLE_HEADERS } from "@/utils/defaultData";
import ExpansionBlock from "../../UI/ExpansionBlock.vue";

export default {
  components: { ExpansionBlock },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    headers: DEV_DASHBOARD_APARSER_DETAILS_TABLE_HEADERS,
    aparserErrorInfoDialog: false,
    aparserErrorInfoData: null,
  }),
  methods: {
    async requestAction({ autoincrement_id }, action) {
      const payload = {
        autoincrement_id,
        action,
      };

      const resp = await this.$store.dispatch(
        "dev-dashboard/requestAction",
        payload
      );

      if (resp) {
        console.log(resp);
      }
    },
    handleOpenErrorDetails(data) {
      this.aparserErrorInfoDialog = true;
      this.aparserErrorInfoData = data.error;
    },
  },
};
</script>
