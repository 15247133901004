import axios from "axios";
import eventPipe from "@/events/eventPipe";

/*eslint-disable no-prototype-builtins*/
class Service {
  TOKEN_EXPIRE_ERROR_TEXT =
    "Your authorization has expired, in order to continue using the application, please authorize again. <a href='/login'><button>Login page</button></a>";
  test_api = "https://api.serpinsider.com/api/s";
  getUrl(prefix = "/api/s") {
    return process.env.VUE_APP_BASE_URL + prefix;
  }

  tossDefaultErrorMessage(text) {
    eventPipe.$emit("error", {
      type: "error",
      title: `Something wrong... 🤕`,
      text: `Error message: ${text}`,
      duration: 6000,
      prompt: true,
    });
  }

  tossCustomErrorMessage({ data, status }) {
    let errorMessage;
    let ol = "<ul>";

    // eslint-disable-next-line no-unused-vars
    Object.entries(data.errors).forEach(([_, error]) => {
      ol += `<li>${error[0]}</li>`;
    });

    ol += "</ul>";

    errorMessage = ol;

    const title = this.buildErrorTitle(status);

    eventPipe.$emit("error", {
      type: "error",
      title,
      text: `# Message:  <br/><br/> <i>"${data.message}"</i> <br/><br/> # Errors: <br/><br/> ${errorMessage}`,
      duration: 0,
      prompt: true,
    });
  }

  buildErrorTitle(status) {
    if (status === 422) return "Validation failed";
    return "Something wrong";
  }

  handleError(error) {
    if (error.response.status === 401) {
      eventPipe.$emit("redirect", { name: "login" });
    }

    const isCustomError =
      error.response && error.response.data && error.response.data.errors;

    if (isCustomError) {
      this.tossCustomErrorMessage(error.response);
    } else {
      this.tossDefaultErrorMessage(error.message);
    }

    throw error;
  }

  async delete(url, configs = {}, is_auth = true, options = {}) {
    try {
      const config = {
        headers: is_auth ? this.auth_headers() : this.no_auth_headers(),
        ...configs,
      };
      const resp = await axios.delete(
        this.getUrl(options?.prefix) + url,
        config
      );

      if (
        resp &&
        resp.data &&
        resp.data.hasOwnProperty("error") &&
        resp.data.error !== null
      ) {
        this.tossDefaultErrorMessage(resp.data.error);
      }

      return resp;
    } catch (error) {
      this.handleError(error);
    }
  }

  async post(url, form_data, is_auth = true, configs = {}, options = {}) {
    try {
      const config = {
        headers: is_auth ? this.auth_headers() : this.no_auth_headers(),
        ...configs,
      };

      const resp = await axios.post(
        this.getUrl(options?.prefix) + url,
        form_data,
        config
      );

      if (
        resp &&
        resp.data &&
        resp.data.hasOwnProperty("error") &&
        resp.data.error !== null
      ) {
        this.tossDefaultErrorMessage(resp.data.error);
      }

      return resp;
    } catch (error) {
      this.handleError(error);
    }
  }

  async get(url, is_auth = true, configs = {}, options = {}) {
    try {
      const config = {
        headers: is_auth ? this.auth_headers() : this.no_auth_headers(),
        ...configs,
      };

      const resp = await axios.get(this.getUrl(options?.prefix) + url, config);

      if (
        resp &&
        resp.data &&
        resp.data.hasOwnProperty("error") &&
        resp.data.error !== null
      ) {
        this.tossDefaultErrorMessage(resp.data.error);
      }

      return resp;
    } catch (error) {
      this.handleError(error);
    }
  }

  async put(url, payload, is_auth = true, configs = {}, options = {}) {
    try {
      const config = {
        headers: is_auth ? this.auth_headers() : this.no_auth_headers(),
        ...configs,
      };

      const resp = await axios.put(
        this.getUrl(options?.prefix) + url,
        payload,
        config
      );

      if (
        resp &&
        resp.data &&
        resp.data.hasOwnProperty("error") &&
        resp.data.error !== null
      ) {
        this.tossDefaultErrorMessage(resp.data.error);
      }

      return resp;
    } catch (error) {
      this.handleError(error);
    }
  }

  auth_headers() {
    const token = this.getToken();
    return {
      "Content-Type": "application/json",
      Authorization: token,
    };
  }
  getToken() {
    const is_string = typeof localStorage.getItem("token") === "string";
    const exist = localStorage.getItem("token") !== "undefined undefined";

    if (is_string && exist) {
      return localStorage.getItem("token");
    }
    return null;
  }
  no_auth_headers() {
    return {
      "Content-Type": "application/json",
    };
  }
}

export default new Service();
