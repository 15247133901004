<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="pageConfig.title"
          :breadcrumbs="pageConfig.breadcrumbs"
        />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <smart-date-filter
              :model-value="filters.date"
              @update:modelValue="filters.date = $event"
              :custom-presets="customDatePresets"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.executives"
              :items="filtersData.users"
              item-value="id"
              item-text="email"
              hide-details
              dense
              outlined
              multiple
              label="Executives"
              placeholder="Type to search"
              :loading="filtersDataPending"
              clearable
            />
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col>
            <v-btn
              color="primary"
              block
              @click="fetchData"
              :loading="loadings.data"
            >
              Get data
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!loadings.data" cols="12">
        <v-card flat outlined rounded="lg" class="pa-4">
          <content-team-performance-chart :data="chartData" />
        </v-card>
      </v-col>
      <v-col v-else>
        <v-skeleton-loader type="table" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { pageConfig } from "./defaults";
import PageHeader from "@/components/UI/PageHeader.vue";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import { ContentTeamPerformanceApiService } from "../../../services/site-workplace/ContentTeamPerformanceApiService";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import ContentTeamPerformanceChart from "./components/ContentTeamPerformanceChart.vue";
import { DEFAULT_MOMENT_DATE_FORMAT } from "../../../utils/defaultData";

export default {
  mixins: [UploadFiltersDataMixin, FiltersHandling],

  components: { ContentTeamPerformanceChart, SmartDateFilter, PageHeader },

  data: () => ({
    filters: {
      date: [null, null],
      executives: [],
    },
    loadings: {
      data: false,
    },
    filtersData: {
      users: [],
    },
    chartData: {
      series: [],
      categories: [],
    },
  }),

  async mounted() {
    await this.parseQuery("filters");
    this.uploadFiltersData(["users"], {});

    if (Object.keys(this.$route.query)?.length) this.fetchData();
  },

  computed: {
    pageConfig() {
      return pageConfig;
    },
    customDatePresets() {
      return [
        {
          id: 1,
          name: "Today",
          range: [
            this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
        {
          id: 2,
          name: "Week",
          range: [
            this.$moment()
              .startOf("isoWeek")
              .format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().endOf("isoWeek").format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
      ];
    },
  },

  methods: {
    async fetchData() {
      try {
        this.loadings.data = true;

        const { data } =
          await ContentTeamPerformanceApiService().getPerformance(this.filters);

        this.chartData = data;
      } catch (e) {
        console.error("Error while fetching dashboard data.", e);
      } finally {
        this.loadings.data = false;
      }
    },
  },
};
</script>
