<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="50"
    :custom-sort="customSort"
    multi-sort
    :sort-by="['pr', 'nt']"
    :sort-desc="[false, false]"
    :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
    class="urls-overview__overview-table"
  >
    <template #body="{ items }">
      <tbody>
        <tr v-for="(row, index) in items" :key="index">
          <uo-td-url :value="row.url" />
          <uo-td-status :value="row.status" />
          <uo-td-l-sc :value="row.l_sc" />
          <uo-td-sc-sc :value="row.sc_sc" />
          <uo-td-sw-sc :value="row.sw_sc" />
          <uo-td-t-sc :value="row.t_sc" />
          <uo-td-nt-percent :value="row.nt_percent" />
          <td class="nt-td">{{ row.nt }}</td>
          <uo-td-pos :data="row.pos" />
          <uo-td-dynamic :value="row.dynamic" />
          <td>{{ row.volume }}</td>
          <td class="pos-td">
            <div class="main-value d-flex align-center justify-center">
              <uo-kd :value="row.kd" :text="true" :opacity="1" />
            </div>
          </td>
          <uo-td-delta :data="row['1']" />
          <uo-td-delta :data="row['3']" />
          <uo-td-delta :data="row['10']" />
          <uo-td-delta :data="row['20']" />
          <td class="green-td">{{ row.r }}</td>
          <td class="red-td">{{ row.f }}</td>
          <uo-td-trends-imp :data="row.trends_imp" />
          <uo-td-trends-cl :data="row.trends_cl" />
          <uo-td-trends-cl :data="row.trends_k" />
          <uo-td-trends-cl :data="row.trends_kr" />
          <uo-td-trends-cl :data="row.trends_kd" />
          <td>{{ row.pr }}</td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import UoTdUrl from "@/components/Dashboards/UrlsOverview/Td/UoTdUrl";
import UoTdDelta from "@/components/Dashboards/UrlsOverview/Td/UoTdDelta";
import UoTdTrendsCl from "@/components/Dashboards/UrlsOverview/Td/UoTdTrendsCl";
import UoTdTrendsImp from "@/components/Dashboards/UrlsOverview/Td/UoTdTrendsImp";
import UoTdPos from "@/components/Dashboards/UrlsOverview/Td/UoTdPos";
import UoTdDynamic from "@/components/Dashboards/UrlsOverview/Td/UoTdDynamic";
import UoTdNtPercent from "@/components/Dashboards/UrlsOverview/Td/UoTdNtPercent";
import UoTdTSc from "@/components/Dashboards/UrlsOverview/Td/UoTdTSc";
import UoTdSwSc from "@/components/Dashboards/UrlsOverview/Td/UoTdSwSc";
import UoTdScSc from "@/components/Dashboards/UrlsOverview/Td/UoTdScSc";
import UoTdLSc from "@/components/Dashboards/UrlsOverview/Td/UoTdLSc";
import UoTdStatus from "@/components/Dashboards/UrlsOverview/Td/UoTdStatus";
import UoKd from "@/components/Dashboards/UrlsOverview/Td/UoKd.vue";

export default {
  components: {
    UoKd,
    UoTdUrl,
    UoTdDelta,
    UoTdTrendsCl,
    UoTdTrendsImp,
    UoTdPos,
    UoTdDynamic,
    UoTdNtPercent,
    UoTdTSc,
    UoTdSwSc,
    UoTdScSc,
    UoTdLSc,
    UoTdStatus,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          let aVal, bVal;
          if (a[sortBy[i]] === null) aVal = 0;
          if (b[sortBy[i]] === null) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
  },
};
</script>
