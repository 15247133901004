<template>
  <v-app id="inspire" class="login-page">
    <v-main>
      <v-container fluid class="fill-height pa-0">
        <v-row style="min-height: 100vh" dense>
          <v-col cols="12" class="pa-0 d-flex flex-column justify-center">
            <div style="width: 360px" class="ma-auto">
              <div>
                <h2 class="d-flex align-center justify-center">
                  <KlihSpaceLogo width="30" class="mr-2" /> Serpinsider
                </h2>
              </div>
              <div class="mt-6">
                <div>
                  <div style="font-size: 12px">Email</div>
                  <v-text-field
                    name="email"
                    v-model="email"
                    type="text"
                    filled
                    placeholder="Enter your email or login"
                    :rules="[(v) => !!v || 'Email required']"
                    hide-details="auto"
                    data-test="email"
                    @keydown.enter="login"
                    class="mb-2"
                  ></v-text-field>
                  <div style="font-size: 12px">Password</div>
                  <v-text-field
                    id="password"
                    name="password"
                    placeholder="Enter password"
                    v-model="password"
                    :type="isPassword ? 'password' : 'text'"
                    filled
                    data-test="password"
                    :rules="[(v) => !!v || 'Password required']"
                    hide-details="auto"
                    :append-icon="
                      isPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                    "
                    @click:append="isPassword = !isPassword"
                    @keydown.enter="login"
                  ></v-text-field>
                </div>
              </div>
              <div class="mt-10">
                <v-btn
                  block
                  class="flat-chip flat-chip--primary reset-btn"
                  @click="login"
                  :loading="loading"
                  style="height: 48px"
                  data-test="confirm-button"
                >
                  Login
                </v-btn>
                <v-btn
                  block
                  class="mt-2 reset-btn"
                  elevation="0"
                  color="transparent"
                  @click="googleAuth"
                  :loading="loading"
                  style="height: 48px"
                  data-test="confirm-button"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 128 128"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                  >
                    <path
                      d="M44.5909 4.21096C31.8017 8.64764 20.7724 17.0686 13.1228 28.237C5.47331 39.4053 1.60684 52.7324 2.09134 66.2606C2.57585 79.7888 7.38581 92.8051 15.8147 103.398C24.2436 113.99 35.8471 121.601 48.9209 125.111C59.5201 127.846 70.625 127.966 81.2809 125.461C90.9341 123.293 99.8587 118.655 107.181 112.001C114.802 104.864 120.333 95.7857 123.181 85.741C126.276 74.8176 126.827 63.3303 124.791 52.161H65.2709V76.851H99.7409C99.052 80.7888 97.5757 84.5473 95.4004 87.9013C93.2251 91.2553 90.3955 94.136 87.0809 96.371C82.8717 99.1553 78.1269 101.029 73.1509 101.871C68.1604 102.799 63.0415 102.799 58.0509 101.871C52.9928 100.825 48.2079 98.7375 44.0009 95.741C37.2424 90.9568 32.1677 84.1602 29.5009 76.321C26.7891 68.3349 26.7891 59.677 29.5009 51.691C31.3992 46.0931 34.5373 40.9963 38.6809 36.781C43.4228 31.8684 49.4262 28.3569 56.0325 26.6317C62.6387 24.9065 69.5925 25.0343 76.1309 27.001C81.2386 28.5689 85.9094 31.3083 89.7709 35.001C93.6576 31.1343 97.5376 27.2576 101.411 23.371C103.411 21.281 105.591 19.291 107.561 17.151C101.667 11.6657 94.7477 7.39753 87.2009 4.59096C73.458 -0.399144 58.4207 -0.533248 44.5909 4.21096Z"
                      fill="white"
                    />
                    <path
                      d="M44.5898 4.20996C58.4184 -0.537472 73.4557 -0.406898 87.1998 4.57996C94.7479 7.40559 101.664 11.6943 107.55 17.2C105.55 19.34 103.44 21.34 101.4 23.42C97.5198 27.2933 93.6431 31.1533 89.7698 35C85.9082 31.3073 81.2374 28.5679 76.1298 27C69.5935 25.0264 62.6399 24.8912 56.032 26.6094C49.424 28.3275 43.4169 31.8326 38.6698 36.74C34.5261 40.9553 31.388 46.0521 29.4898 51.65L8.75977 35.6C16.1798 20.8856 29.0273 9.6302 44.5898 4.20996Z"
                      fill="#E33629"
                    />
                    <path
                      d="M3.2595 51.4996C4.37372 45.9775 6.22354 40.6299 8.7595 35.5996L29.4895 51.6896C26.7776 59.6757 26.7776 68.3335 29.4895 76.3196C22.5828 81.6529 15.6728 87.0129 8.7595 92.3996C2.41101 79.7628 0.474829 65.3646 3.2595 51.4996Z"
                      fill="#F8BD00"
                    />
                    <path
                      d="M65.2701 52.1504H124.79C126.826 63.3197 126.275 74.8071 123.18 85.7304C120.332 95.7752 114.801 104.854 107.18 111.99C100.49 106.77 93.7701 101.59 87.0801 96.3704C90.3968 94.1332 93.2278 91.2493 95.4032 87.8917C97.5786 84.5341 99.0537 80.7718 99.7401 76.8304H65.2701C65.2601 68.6104 65.2701 60.3804 65.2701 52.1504Z"
                      fill="#587DBD"
                    />
                    <path
                      d="M8.75 92.4003C15.6633 87.067 22.5733 81.707 29.48 76.3203C32.1521 84.1623 37.2341 90.9593 44 95.7403C48.2201 98.723 53.0153 100.794 58.08 101.82C63.0706 102.748 68.1894 102.748 73.18 101.82C78.156 100.978 82.9008 99.1046 87.11 96.3203C93.8 101.54 100.52 106.72 107.21 111.94C99.8889 118.598 90.9642 123.239 81.31 125.41C70.6541 127.915 59.5492 127.795 48.95 125.06C40.567 122.822 32.7368 118.876 25.95 113.47C18.7666 107.767 12.8996 100.58 8.75 92.4003Z"
                      fill="#319F43"
                    />
                  </svg>
                  Sign in with Google
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import KlihSpaceLogo from "../UI/KlihSpaceLogo.vue";
import service from "../../plugins/service";

export default {
  name: "LoginFormIndex",
  components: { KlihSpaceLogo },
  props: {
    source: String,
  },
  mounted() {
    if (service.isTokenExpired(this.$store.getters["auth/token"]))
      this.$store.commit("setAuthToken", null);

    if (this.$store.getters["auth/token"] !== null) this.$router.push("/");
  },
  data() {
    return {
      isPassword: true,
      snackbar: false,
      loading: false,
      email: "",
      password: "",
    };
  },
  computed: {
    message() {
      return this.$store.state.auth.response.msg;
    },
  },
  methods: {
    googleAuth() {
      console.log(process.env.VUE_APP_API_URL);
      window.location.href = `${service.getUrl("")}/auth/google/redirect`;
    },
    login() {
      let self = this;

      self.loading = true;

      self.$store.dispatch("login", {
        email: self.email,
        password: self.password,
        then() {
          self.loading = false;

          self.$store.dispatch("fetch_countries_list");
          self.$store.dispatch("fetch_languages_list");
          self.$store.dispatch("fetch_niches_list");

          self.$gtag.config({
            user_id: self.$store.state.auth.user.id,
          });

          if (
            self.$router.history._startLocation !== "/" &&
            self.$router.history._startLocation !== "/login" &&
            self.$router.history._startLocation !== undefined
          ) {
            self.$router.push(self.$router.history._startLocation);
          } else {
            self.$router.push("/");
          }
        },
        catch() {
          self.loading = false;
          self.snackbar = true;
        },
      });
    },
  },
};
</script>
