<template>
  <td>
    <div class="d-flex justify-space-between align-center" style="width: 100px;">
      <div>
        <span :style="{color:getColor(0),whiteSpace:'nowrap'}">
          <v-icon :color="getColor(0)" style="width: 7px;">
            {{ getIcon(0) }}
          </v-icon>
          {{ getFixedValue[0] === 0 ? '-' : getFixedValue[0]}}
        </span>
      </div>
      <div style="white-space: nowrap">
        <span :style="{color:getColor(1),whiteSpace:'nowrap'}" >
          <v-icon :color="getColor(1)" style="width: 7px;">
            {{ getIcon(1) }}
          </v-icon>
          {{ getFixedValue[1] === 0 ? '-' : getFixedValue[1]}}
        </span>
      </div>
      <div style="white-space: nowrap">
        <span :style="{color: getColor(2),whiteSpace:'nowrap'}">
          <v-icon :color="getColor(2)" style="width: 7px;">
            {{ getIcon(2) }}
          </v-icon>
          {{ getFixedValue[2] === 0 ? '-' : getFixedValue[2]}}
        </span>
      </div>
    </div>
  </td>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    getFixedValue() {
      return this.value.map(value => parseInt(String(value).replaceAll('-','')))
    },
  },
  methods: {
    getColor(idx) {
      const value = this.value[idx]
      if(value === 0) {
        return 'inherit'
      }
      if(value > 0) {
        return 'green'
      }
      return 'red'
    },
    getIcon(idx) {
      const value = this.value[idx]
      if(value === 0) {
        return ''
      }
      if(value > 0) {
        return 'mdi-triangle-small-up'
      }
      return 'mdi-triangle-small-down'
    },
  }
}
</script>
