<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="280"
    transition="slide-y-transition"
    offset-x
    offset-y
    content-class="my-shadow--e3"
    max-height="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-badge
          :content="notification_count"
          :value="notification_count > 0"
          color="pink"
          overlap
        >
          <v-icon v-if="notification_count > 0">mdi-bell-ring</v-icon>
          <v-icon v-else>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card max-width="400" class="mx-auto styled-card--default">
      <v-slide-y-transition>
        <v-card-title class="pa-2">
          <v-item-group
            active-class="primary--text"
            class="my-group my-group--two"
          >
            <v-chip
              small
              label
              outlined
              :color="mode === 'all' ? 'primary' : ''"
              @click="mode = 'all'"
            >
              last
            </v-chip>
            <v-chip
              small
              label
              outlined
              :color="mode === 'new' ? 'primary' : ''"
              @click="mode = 'new'"
            >
              new
            </v-chip>
          </v-item-group>
          <v-spacer />
          <v-chip small color="transparent" label @click="handleMarkAllAsRead">
            <span class="pink--text"
              >mark all read <v-icon small right>mdi-eye-outline</v-icon></span
            >
          </v-chip>
        </v-card-title>
      </v-slide-y-transition>
      <v-divider />
      <template
        v-if="
          getFilteredNotificationItems.length > 0 &&
          !loadings.uploadNotifications
        "
      >
        <v-slide-x-transition
          style="max-height: 400px; overflow: scroll"
          class="v-sheet hide-scrollbar theme--light v-list--dense v-list--nav v-list--two-line px-4"
          group
          tag="v-list"
        >
          <template v-for="(item, i) in getFilteredNotificationItems">
            <v-list-item :key="`${i}-${item.title}`">
              <template #default="{ active }">
                <v-list-item-icon class="mr-4">
                  <notification-icon :notification="item" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :class="{ 'pink--text': active }"
                    class="d-flex"
                  >
                    {{ item.content.title }}
                    <v-spacer />
                    <small>
                      {{ item.created_at }}
                    </small>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    style="margin-top: 4px; opacity: 0.8"
                    v-text="item.content.message"
                  >
                  </v-list-item-subtitle>
                  <div v-if="item.content.link" class="mt-2">
                    <v-chip @click="goTo(item.content.link)" label outlined>
                      Download file <v-icon small right>mdi-download</v-icon>
                    </v-chip>
                  </div>
                </v-list-item-content>

                <v-list-item-action
                  v-if="
                    item.viewed === 0 &&
                    notificationViewed.indexOf(item.id) === -1
                  "
                >
                  <v-chip
                    color="transparent"
                    label
                    @click="setNotificationViewed(item, true)"
                    small
                  >
                    Read
                    <v-icon
                      v-if="!loadings.setViewed.includes(item.id)"
                      small
                      label
                      right
                      color="pink"
                    >
                      mdi-bell
                    </v-icon>
                    <v-progress-circular
                      v-else
                      size="16"
                      width="2"
                      color="pink"
                      class="ml-2"
                      indeterminate
                    />
                  </v-chip>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
              v-if="i !== getFilteredNotificationItems.length - 1"
              :key="`${i}-${item.title}-divider`"
            />
          </template>
        </v-slide-x-transition>
      </template>
      <div
        v-else-if="loadings.uploadNotifications"
        class="d-flex justify-center align-center"
        style="height: 150px"
      >
        <v-progress-circular
          indeterminate
          size="54"
          width="2"
          color="primary"
        ></v-progress-circular>
      </div>
      <v-alert v-else class="mb-0 text-center">
        <div class="my-6"></div>
        <v-icon size="60" style="opacity: 0.5">
          mdi-emoticon-cry-outline
        </v-icon>
        <div class="my-2">
          <span style="font-size: 12px">No new notifications</span>
        </div>
      </v-alert>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-chip
          outlined
          label
          small
          title="Upload new notifications"
          @click="handleUpdateNewNotifications"
          :disabled="loadings.uploadNotifications"
        >
          Upload new notifications
          <v-icon v-if="!loadings.uploadNotifications" small class="ml-2"
            >mdi-refresh</v-icon
          >
          <v-progress-circular
            v-else
            size="14"
            width="2"
            class="ml-2"
            indeterminate
          />
        </v-chip>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import service from "@/plugins/service";
import NotificationIcon from "@/components/Main/Notifications/NotificationIcon.vue";

// type content = {
//   data: object[];
//   link: string;
//   message: string;
//   title: string;
// }
//
// type message = {
//   content: content;
//   created_at: string;
//   group: string;
//   id: number;
//   viewed: 0 | 1;
// }
//
// type reponse = {
//   items: message[];
// }

export default {
  name: "Notifications",
  components: { NotificationIcon },
  data() {
    return {
      loadings: {
        setViewed: [],
        uploadNotifications: false,
      },
      mode: "all",
      loading: false,
      menu: false,
      selected: [2],
      notification_count: 0,
      notification_items: [],
      notification_viewed: [],
    };
  },
  computed: {
    getFilteredNotificationItems() {
      try {
        if (this.mode === "all") return this.notification_items || [];

        return this.notification_items.filter((v) => !v.viewed);
      } catch {
        return [];
      }
    },
    notificationViewed() {
      return this.notification_viewed;
    },
  },
  methods: {
    checkIsNotEmpty(data, arrayOfValueNames) {
      let output = 0;
      Object.keys(data).forEach((key) => {
        if (output > 0) return;

        if (arrayOfValueNames.includes(key)) {
          if (Array.isArray(data[key]) && data[key].length > 0) return output++;
          if (data[key]) output++;
        }
      });

      return output > 0;
    },
    handleUpdateNewNotifications() {
      this.fetchNotifications();
    },
    handleMarkAllAsRead() {
      const promises = [];

      this.getFilteredNotificationItems.forEach((item) => {
        promises.push(this.setNotificationViewed(item));
      });

      Promise.all(promises).then(() => {
        this.handleUpdateNewNotifications();
      });
    },
    goTo(link) {
      window.open(link);
    },
    async setNotificationViewed(item, single = false) {
      try {
        const url = "/notifications/viewed";

        const configs = {
          params: {
            id: item.id,
          },
        };

        this.loadings.setViewed.push(item.id);

        const resp = await service.put(url, {}, true, configs);

        this.loadings.setViewed = this.loadings.setViewed.filter(
          (v) => v !== item.id
        );

        if (resp && resp.data && resp.data.success === 1) {
          this.notification_viewed.push(item.id);
          item.viewed = 1;

          const allItemsViewed =
            this.notification_items.filter((v) => !v.viewed).length === 0;

          if (single && allItemsViewed) this.handleUpdateNewNotifications();
        }
      } catch (e) {
        this.loadings.setViewed = this.loadings.setViewed.filter(
          (v) => v !== item.id
        );
        console.error(e);
      }
    },
    processNotificationsResponse(responseData) {
      this.notification_count = responseData.data.items.filter(
        (v) => !v.viewed
      ).length;
      this.notification_items = [...responseData.data.items];
    },
    async fetchNotifications() {
      try {
        const url = "/notifications/all";

        this.loadings.uploadNotifications = true;
        this.notification_items = [];

        const response = await service.get(url);

        this.processNotificationsResponse(response);
      } catch (e) {
        console.error("Error while loading notifications.", e);
      } finally {
        this.loadings.uploadNotifications = false;
      }
    },
  },
  created() {
    let self = this;

    self.fetchNotifications();

    setInterval(function () {
      self.fetchNotifications();
    }, 300000);
  },
};
</script>

<style scoped></style>
