<template>
  <v-container
    fluid
    style="padding-bottom: 200px"
    class="dashboard-dev-detailed-page"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-row dense>
            <v-col>
              <h2>Detailed</h2>
              <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
            </v-col>
            <v-col class="d-flex justify-end align-start">
              <v-menu
                nudge-bottom="40"
                bottom
                content-class="remove-dialog-shadow pa-1"
                :close-on-content-click="false"
              >
                <template #activator="{ on }">
                  <v-chip
                    label
                    color="transparent"
                    class="primary--text"
                    v-on="on"
                  >
                    <v-icon left small>mdi-cog</v-icon> Settings
                    <v-icon right small>mdi-chevron-down</v-icon>
                  </v-chip>
                </template>
                <v-card class="styled-card--default" width="340">
                  <v-card-title> Settings </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-switch
                          v-model="isUploadAllWhenEnterPage"
                          dense
                          hide-details
                          label="Upload all when enter the page"
                          class="ma-0"
                          :true-value="'1'"
                          :false-value="'0'"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          v-model="isShowTilesComputed"
                          dense
                          hide-details
                          label="Tiles"
                          class="ma-0"
                          :true-value="'1'"
                          :false-value="'0'"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          v-model="isResetCache"
                          dense
                          hide-details
                          label="Reset cache"
                          class="ma-0"
                          :true-value="true"
                          :false-value="null"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          v-model="settings.isAccordion"
                          dense
                          hide-details
                          label="Accordion"
                          class="ma-0"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-menu
                left
                nudge-bottom="40"
                content-class="remove-dialog-shadow"
                :close-on-content-click="false"
              >
                <template #activator="{ on }">
                  <v-chip class="ml-2" v-on="on" label color="primary">
                    <v-icon left small>mdi-filter</v-icon>
                    Filters
                    <v-icon small right>mdi-chevron-down</v-icon>
                  </v-chip>
                </template>
                <v-card class="styled-card--default" width="240">
                  <v-card-title> Filters </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="mode"
                          :items="modeItems"
                          hide-details
                          dense
                          filled
                          label="Content type"
                          placeholder="Type to search"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="styled-card--default">
          <v-card-title class="d-flex">
            List of actions
            <v-spacer />
            <v-chip
              label
              :disabled="loadings.fetchAll || loadings.actions"
              color="primary"
              @click="handleUploadAddSections"
              >Upload all actions
              <v-progress-circular
                v-if="loadings.fetchAll"
                size="18"
                width="2"
                class="ml-2"
                indeterminate
              />
              <v-icon v-else right>mdi-plus</v-icon>
            </v-chip>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <v-expansion-panels v-model="expansionPanelsActionsListModel" hover>
              <v-expansion-panel class="remove-dialog-shadow">
                <v-expansion-panel-header>
                  <div>
                    Expand to see all actions
                    <v-icon small>mdi-arrow-expand-vertical</v-icon>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense class="mb-8">
                    <v-col cols="12" class="d-flex">
                      <v-text-field
                        v-model="search"
                        label="Search"
                        clearable
                        append-icon="mdi-magnify"
                        hide-details
                        dense
                        style="max-width: 280px"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="loadings.actions" dense>
                    <template>
                      <v-col
                        v-for="idx in [0, 1, 2, 3, 4, 5]"
                        :key="idx"
                        cols="12"
                      >
                        <div class="skeleton" style="height: 40px"></div>
                      </v-col>
                    </template>
                  </v-row>
                  <v-row v-else dense>
                    <template v-if="isShowTiles || Number(isShowTilesComputed)">
                      <template v-for="(action, idx) in actions">
                        <v-col
                          v-if="
                            !search ||
                            String(action.name)
                              .toLowerCase()
                              .includes(String(search).toLowerCase())
                          "
                          :key="action.id + idx"
                          cols="4"
                        >
                          <v-card class="shadow-e1-bordered" rounded="lg">
                            <v-card-title>
                              <v-row dense>
                                <v-col cols="10">
                                  <span style="font-size: 16px">
                                    {{ action.name }}
                                  </span>
                                  <v-menu
                                    bottom
                                    content-class="remove-dialog-shadow pa-1"
                                    :close-on-content-click="false"
                                  >
                                    <template #activator="{ on }">
                                      <v-icon
                                        v-on="on"
                                        small
                                        color="rgba(155,155,155,0.8)"
                                        >mdi-information-outline</v-icon
                                      >
                                    </template>
                                    <v-card class="pa-4">
                                      <v-simple-table dense>
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th>Value</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(key, idx) in Object.keys(
                                              action
                                            )"
                                            :key="key + idx"
                                          >
                                            <td>
                                              {{ key }}
                                            </td>
                                            <td>
                                              {{ action[key] }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </v-simple-table>
                                    </v-card>
                                  </v-menu>
                                </v-col>
                                <v-col cols="2" class="d-flex justify-end">
                                  <actions-table-icon
                                    :data="action"
                                    @click="handleUploadSection(action)"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-title>

                            <v-card-subtitle>
                              {{ action.service }}
                            </v-card-subtitle>
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="8">
                                  <v-list dense>
                                    <v-list-item class="px-0">
                                      <v-list-item-icon>
                                        <v-icon>mdi-account-outline</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        {{ action.responsible }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="px-0">
                                      <v-list-item-icon>
                                        <v-icon>mdi-calendar</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        {{
                                          $moment(action.updated_at).fromNow()
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                                <v-col
                                  cols="4"
                                  class="d-flex justify-end align-end"
                                >
                                  <v-chip
                                    :outlined="
                                      !sectionWithUploadError.includes(
                                        action.id
                                      )
                                    "
                                    :color="
                                      sectionWithUploadError.includes(action.id)
                                        ? 'error'
                                        : ''
                                    "
                                    @click="handleUploadSection(action)"
                                  >
                                    <template
                                      v-if="
                                        sectionWithUploadError.includes(
                                          action.id
                                        )
                                      "
                                    >
                                      Upload error
                                    </template>
                                    <template
                                      v-else-if="
                                        uploadedSections.includes(action.id)
                                      "
                                    >
                                      Refresh
                                    </template>
                                    <template v-else> Upload </template>

                                    <v-progress-circular
                                      v-if="
                                        loadings.sections.includes(action.id)
                                      "
                                      size="18"
                                      width="2"
                                      class="ml-2"
                                      indeterminate
                                    />
                                    <v-icon v-else right small>
                                      <template
                                        v-if="
                                          sectionWithUploadError.includes(
                                            action.id
                                          )
                                        "
                                      >
                                        mdi-alert
                                      </template>
                                      <template
                                        v-else-if="
                                          uploadedSections.includes(action.id)
                                        "
                                      >
                                        mdi-refresh
                                      </template>
                                      <template v-else> mdi-plus </template>
                                    </v-icon>
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </template>
                    </template>
                    <template v-else>
                      <v-col cols="12">
                        <v-data-table
                          :headers="actionsTableHeaders"
                          :items="actions"
                          :search="search"
                          :sort-by="['service_priority', 'priority']"
                          :sort-desc="[true, true]"
                          multi-sort
                          :custom-sort="_$smartCustomTableSort"
                          :items-per-page="25"
                          :footer-props="{
                            itemsPerPageOptions: [25, 50, 100, -1],
                          }"
                        >
                          <template
                            #[`group.header`]="{ isOpen, toggle, group }"
                          >
                            <td>
                              <v-btn icon @click="toggle">
                                <v-icon>
                                  {{
                                    isOpen
                                      ? "mdi-chevron-down"
                                      : "mdi-chevron-right"
                                  }}
                                </v-icon>
                              </v-btn>
                              {{ group }}
                            </td>
                            <td :colspan="actionsTableHeaders.length - 1"></td>
                          </template>
                          <template #[`item._status`]="{ item }">
                            <v-chip
                              :outlined="
                                !sectionWithUploadError.includes(item.id)
                              "
                              :color="
                                sectionWithUploadError.includes(item.id)
                                  ? 'error'
                                  : ''
                              "
                              @click="handleUploadSection(item)"
                            >
                              <template
                                v-if="sectionWithUploadError.includes(item.id)"
                              >
                                Upload error
                              </template>
                              <template
                                v-else-if="uploadedSections.includes(item.id)"
                              >
                                Refresh
                              </template>
                              <template v-else> Upload </template>

                              <v-progress-circular
                                v-if="loadings.sections.includes(item.id)"
                                size="18"
                                width="2"
                                class="ml-2"
                                indeterminate
                              />
                              <v-icon v-else right small>
                                <template
                                  v-if="
                                    sectionWithUploadError.includes(item.id)
                                  "
                                >
                                  mdi-alert
                                </template>
                                <template
                                  v-else-if="uploadedSections.includes(item.id)"
                                >
                                  mdi-refresh
                                </template>
                                <template v-else> mdi-plus </template>
                              </v-icon>
                            </v-chip>
                          </template>
                          <template #[`item.worksection`]="{ item }">
                            <a
                              v-if="item.worksection"
                              :href="item.worksection"
                              target="_blank"
                              :title="item.worksection"
                            >
                              <v-btn icon>
                                <v-icon small>mdi-open-in-new</v-icon>
                              </v-btn>
                            </a>
                          </template>
                          <template #[`item.updated_at`]="{ value }">
                            <template
                              v-if="
                                $moment(value).isBefore(
                                  $moment().subtract(1, 'days')
                                )
                              "
                            >
                              <v-chip
                                class="flat-chip flat-chip--error"
                                title="Last update more then 1 day ago"
                              >
                                {{ value }}
                              </v-chip>
                            </template>
                            <template v-else>
                              <v-chip color="transparent">
                                {{ value }}
                              </v-chip>
                            </template>
                          </template>
                        </v-data-table>
                      </v-col>
                    </template>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-card class="styled-card--default">
              <v-card-title class="d-flex">
                Uploaded sections <v-spacer />
                <v-chip
                  class="flat-chip--primary mr-2"
                  label
                  @click="toggleExpandedSections"
                >
                  Expand/Collapse sections
                  <v-icon small right>mdi-format-vertical-align-center</v-icon>
                </v-chip>
                <v-menu
                  bottom
                  content-class="remove-dialog-shadow"
                  :close-on-content-click="false"
                >
                  <template #activator="{ on }">
                    <v-chip
                      v-on="on"
                      class="flat-chip--primary"
                      @click="() => {}"
                      label
                    >
                      <v-icon small left>mdi-radar</v-icon>
                      Navigate to
                      <v-icon small right>mdi-chevron-down</v-icon>
                    </v-chip>
                  </template>

                  <page-navigation
                    selector="[data-page-navigation]"
                    :update-sections="updateNavigationWidget"
                  />
                </v-menu>
              </v-card-title>
              <v-divider />
              <v-card-text class="pa-0">
                <template v-if="Object.values(sections).length === 0">
                  <div class="text-center py-4">
                    <span style="opacity: 0.8">
                      Select sections to upload...
                    </span>
                  </div>
                </template>
                <v-expansion-panels
                  v-model="expansionPanels"
                  :accordion="settings.isAccordion"
                  :multiple="!settings.isAccordion"
                  hover
                >
                  <v-expansion-panel
                    v-for="(sectionsData, idx) in getSortedByPriority(
                      Object.values(sections),
                      'sections'
                    )"
                    :key="sectionsData.name + idx"
                  >
                    <v-expansion-panel-header disable-icon-rotate>
                      <div>
                        <span style="font-size: 16px; font-weight: 600">
                          <span>{{ sectionsData.name }}</span>
                          <v-progress-circular
                            v-if="
                              loadings.actionDetails[sectionsData.name].length >
                              0
                            "
                            class="ml-2"
                            indeterminate
                            size="18"
                            width="2"
                          />
                        </span>
                      </div>
                      <template #actions>
                        <v-icon
                          v-if="sectionsWithErrors[sectionsData.name]"
                          color="error"
                          >mdi-alert-circle</v-icon
                        >
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row dense>
                        <v-col
                          v-for="(skeleton, idx) in loadings.actionDetails[
                            sectionsData.name
                          ]"
                          :key="skeleton + idx"
                          cols="12"
                        >
                          <v-row dense>
                            <v-col>
                              <div class="skeleton" style="height: 275px" />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          v-for="(section, idx) in getSortedByPriority(
                            sectionsData.items,
                            'items'
                          )"
                          :key="section.id + idx"
                          cols="12"
                        >
                          <!--not render while loading / refreshing -->
                          <template
                            v-if="
                              !loadings.actionDetails[
                                sectionsData.name
                              ].includes(section.id)
                            "
                          >
                            <template v-for="(card, idx) in section.data">
                              <v-card
                                :data-page-navigation="`${
                                  card.sectionName || card.section_name
                                }`"
                                class="remove-dialog-shadow"
                                rounded="lg"
                                :key="card.sectionName + idx"
                              >
                                <dashboard-dev-section-block-factory
                                  v-if="card.items && card.items.length > 0"
                                  :card-data="card"
                                  :section-data="section"
                                  :is-card="true"
                                  @refresh="fetchSection"
                                  @createTask="handleCreateTask"
                                  @createFastTask="handleCreateFastTask"
                                />
                              </v-card>
                            </template>
                          </template>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <template v-if="loadings.sectionGroup.length > 0">
                  <v-divider />
                  <div class="py-4 text-center">
                    <v-progress-circular
                      size="32"
                      width="2"
                      color="primary"
                      indeterminate
                    />
                  </div>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-menu
      left
      content-class="shadow-e2-bordered"
      rounded="lg"
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <v-btn
          fab
          fixed
          right
          bottom
          color="primary"
          style="right: 80px"
          v-model="fab"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense min-width="240px">
        <v-menu
          bottom
          content-class="shadow-e2-bordered"
          rounded="lg"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-list-item v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-radar</v-icon>
              </v-list-item-icon>
              <v-list-item-content> Page navigation </v-list-item-content>
            </v-list-item>
          </template>
          <page-navigation
            selector="[data-page-navigation]"
            :update-sections="updateNavigationWidget"
          />
        </v-menu>
        <v-list-item @click="toggleExpandedSections">
          <v-list-item-icon>
            <v-icon>mdi-format-vertical-align-center</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Expand/Collapse all sections
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      @click="scrollToTop"
      outlined
      fab
      fixed
      right
      color="info"
      bottom
      class="mr-2"
      style="right: 10px"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-dialog
      v-model="createTaskDialogModel"
      persistent
      content-class="remove-dialog-shadow"
      width="auto"
    >
      <v-card class="styled-card--default" rounded="lg">
        <v-card-title style="font-size: 14px">
          Create task
          <v-spacer />
          <v-btn icon @click="createTaskDialogModel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="d-flex justify-center pt-4 px-0">
          <task-card
            :form="form"
            borderless
            :filters-data="filtersData"
            :loadings="loadings"
            @createTask="handleCreateSubtask"
            disable-create-button
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex">
          <v-spacer />
          <v-chip
            label
            color="transparent"
            class="error--text mr-2"
            @click="createTaskDialogModel = false"
          >
            Close</v-chip
          >
          <v-chip
            :disabled="loadings.createSubtask"
            color="success"
            style="width: 130px"
            class="d-flex justify-center"
            label
            @click="handleCreateSubtask"
          >
            Create
          </v-chip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DashboardDevSectionBlockFactory from "../components/DashboardDevSectionBlockFactory.vue";
import service from "../../../plugins/service";
import ActionsTableIcon from "../components/ActionsTableIcon.vue";
import PageNavigationMixin from "../../../mixins/PageNavigationMixin";
import { DEV_MONITOR_ACTIONS_DATA } from "../../../utils/defaultData";
import TableSorting from "../../../mixins/TableSorting";
import PageNavigation from "../../UI/PageNavigation.vue";
import QueryBuilder from "@/mixins/QueryBuilder";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import TaskCard from "@/components/SimilarTaskCreating/UI/TaskCard.vue";
import SettingsMixin from "@/mixins/SettingsMixin";

export default {
  components: {
    TaskCard,
    PageNavigation,
    ActionsTableIcon,
    DashboardDevSectionBlockFactory,
  },
  mixins: [
    PageNavigationMixin,
    TableSorting,
    QueryBuilder,
    FiltersHandling,
    SettingsMixin,
  ],
  data: () => ({
    settings: {
      isAccordion: false,
    },
    localStorageId: "dashboard-dev-settings-14sdj8a",
    createTaskDialogModel: false,
    expansionPanelsActionsListModel: 0,
    fab: false,
    expansionPanels: [],
    UPLOAD_ALL_LS_KEY: "dashboard-dev-is-upload-all",
    SHOW_TILES_LS_KEY: "dashboard-dev-is-show-tiles",
    sections: {},
    actionsTableHeaders: DEV_MONITOR_ACTIONS_DATA.headers,
    actions: [],
    search: null,
    updateNavigationWidget: false,
    sectionWithUploadError: [],
    sectionsWithErrors: {},
    uploadedSections: [],
    filtersData: {
      executive: [],
      labels: [],
    },
    form: {
      project_id: 171238, // required
      parent_task_id: null, // required
      reporter: null, // required
      task_title: null, // required
      task_desc: null,
      priority: 8,
      date: [],
      tags: [641699],
      estimated_time: null,
      estimated_money: null,
    },
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Dashboard Dev",
        to: "/dashboard-dev",
        exact: true,
        disabled: false,
      },
      {
        text: "Detailed",
        to: "/dashboard-dev/detailed",
        disabled: true,
      },
    ],
    isShowTiles: false,
    filters: {
      only_problem_data: null,
    },
    mode: "detailed",
    modeItems: [
      {
        text: "All",
        value: 0,
      },
      {
        text: "Only problem",
        value: 1,
      },
      {
        text: "Detailed",
        value: "detailed",
      },
    ],
    isResetCache: null,
    loadings: {
      actions: false,
      fetchAll: false,
      sections: [],
      createSubtask: false,
      sectionGroup: [],
      actionDetails: {},
    },
  }),

  async created() {
    this.fetchFilters();
    await this.fetchMonitorActions();

    const mode = this.$route.query.mode;
    if (mode && mode !== "detailed") {
      this.mode = Number(mode);
    }

    if (Number(this.isUploadAllWhenEnterPage)) this.fetchSections();
  },
  watch: {
    mode(value) {
      this._$handleFilterChange(value, "mode");
    },
  },
  computed: {
    isUploadAllWhenEnterPage: {
      get() {
        return window.localStorage.getItem(this.UPLOAD_ALL_LS_KEY);
      },
      set(value) {
        window.localStorage.setItem(this.UPLOAD_ALL_LS_KEY, value);
      },
    },
    isShowTilesComputed: {
      get() {
        return window.localStorage.getItem(this.SHOW_TILES_LS_KEY);
      },
      set(value) {
        window.localStorage.setItem(this.SHOW_TILES_LS_KEY, value);
        this.isShowTiles = !!Number(value);
      },
    },
  },
  methods: {
    async fetchFilters() {
      this.loadings.filters = true;
      const resp = await this.$store.dispatch(
        "worksectionReportDaily/fetchFilters",
        {}
      );
      if (resp) {
        this.filtersData.executive = resp.executives;
        this.filtersData.labels = resp.labels;
      }
      this.loadings.filters = false;
    },
    async handleCreateSubtask() {
      try {
        const url = "/worksection/task/create";

        const payload = {
          type: "CREATE_TASKS",
          main_task: this.form,
        };

        this.loadings.createSubtask = true;

        const resp = await service.post(url, payload);

        if (resp && resp.data.success) {
          this.createTaskDialogModel = false;
          this.createdTaskData = resp.data.task;

          return this.$message.notification({
            title: "Successfully created",
            text: `<a
                      href="${
                        "https://ra.worksection.com/" +
                        this.createdTaskData.page
                      }"
                      target="_blank"
                  >${this.createdTaskData.name}</a
                  >`,
            type: "success",
          });
        }

        this.$message.notification({
          title: "Something wrong.",
          text: resp.data.message,
          type: "error",
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.createSubtask = false;
      }
    },
    clearForm() {
      this.form = {
        project_id: 171238,
        parent_task_id: null,
        reporter: null,
        task_title: null,
        task_desc: null,
        priority: 8,
        date: [],
        tags: [641699],
        estimated_time: null,
        estimated_money: null,
      };
    },
    handleCreateTask(data) {
      this.form.task_title = data.section_name;
      this.form.reporter = data.executive;

      const start = this.$moment(new Date())
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).endOf("day").format("YYYY-MM-DD");

      this.form.date = [start, end];

      this.createTaskDialogModel = true;
    },
    handleCreateFastTask(data) {
      console.log(data);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toggleExpandedSections() {
      if (this.expansionPanels.length !== 0) return (this.expansionPanels = []);

      this.expansionPanels = [
        ...Array(Object.keys(this.sections).length).keys(),
      ].map((k, i) => i);

      this.updateNavigationWidget = true;
      setTimeout(() => {
        this.updateNavigationWidget = false;
      }, 200);
    },
    handleUploadAddSections() {
      this.fetchSections();
    },
    fetchSections() {
      const promises = [];

      this.loadings.fetchAll = true;

      this.actions.forEach((action) => {
        promises.push(this.fetchSection(action));
      });

      Promise.all(promises).finally(() => {
        this.loadings.fetchAll = false;
      });
    },
    handleUploadSection(sectionData) {
      this.fetchSection(sectionData);
    },
    async fetchMonitorActions() {
      try {
        const payload = {
          params: {
            only_problem_data: this.mode,
          },
        };

        this.loadings.actions = true;

        const resp = await this.$store.dispatch(
          "dev-dashboard/monitorActions",
          payload
        );

        if (resp) {
          this.actions = resp;
        }
      } finally {
        this.loadings.actions = false;
      }
    },
    getSortedByPriority(items = []) {
      try {
        return items.sort((a, b) => {
          if (a.priority > b.priority) return -1;
          if (a.priority < b.priority) return 1;
          if (a.priority === b.priority) return 0;
        });
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async fetchSection(sectionData) {
      try {
        const id = sectionData.id;
        const url = "/dashboards/dev/monitor/action/" + id;

        const configs = {
          params: {
            only_problem_data: this.mode,
            reset_cache: this.isResetCache,
          },
        };

        if (this.loadings.actionDetails[sectionData.service] === undefined)
          this.loadings.actionDetails[sectionData.service] = [];

        if (Array.isArray(this.loadings.actionDetails[sectionData.service])) {
          this.loadings.actionDetails[sectionData.service].push(id);
        }

        this.$forceUpdate();

        this.loadings.sections.push(id);

        if (!this.loadings.sectionGroup.includes(sectionData.service))
          this.loadings.sectionGroup.push(sectionData.service);

        const resp = await service.get(url, true, configs);

        if (resp) {
          this.sectionWithUploadError = this.sectionWithUploadError.filter(
            (v) => v !== id
          );

          if (!this.sections[sectionData.service]) {
            this.sections[sectionData.service] = {
              priority: sectionData.service_priority,
              expanded: true,
              name: sectionData.service,
              items: [],
            };
          }

          const idx = this.sections[sectionData.service].items.findIndex(
            (v) => v.id === id
          );

          const newItem = {
            data: resp.data.items,
            priority: sectionData.priority,
            sectionData,
            id,
          };

          if (idx === -1) {
            this.sections[sectionData.service].items.push(newItem);
          } else {
            this.sections[sectionData.service].items[idx] = newItem;
          }

          this.sections[sectionData.service].items[idx] = {
            data: resp.data.items,
            priority: sectionData.priority,
            sectionData,
            id,
          };

          if (!this.uploadedSections.includes(id))
            this.uploadedSections.push(id);

          this.$forceUpdate();
        }
      } catch (e) {
        console.error(e);
        this.sectionWithUploadError.push(sectionData.id);
        this.sectionsWithErrors[sectionData.service] = true;
      } finally {
        this.loadings.sectionGroup = this.loadings.sectionGroup.filter(
          (v) => v !== sectionData.service
        );

        this.loadings.sections = this.loadings.sections.filter(
          (v) => v !== sectionData.id
        );

        if (
          this.loadings.actionDetails[sectionData.service] !== undefined &&
          Array.isArray(this.loadings.actionDetails[sectionData.service]) &&
          this.loadings.actionDetails[sectionData.service].includes(
            sectionData.id
          )
        ) {
          this.loadings.actionDetails[sectionData.service] =
            this.loadings.actionDetails[sectionData.service].filter(
              (v) => v !== sectionData.id
            );
        }
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard-dev-detailed-page {
  .v-expansion-panel {
    &:before {
      box-shadow: none !important;
    }
  }
}
</style>
