<template>
  <div class="mb-2">
    {{ data.text }}
    <v-progress-linear
      :value="data.value"
      :color="getProgressColor"
      height="22"
    >
      <template v-slot:default="{ value }">
        <b>{{ value }}%</b>
      </template>
    </v-progress-linear>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getProgressColor() {
      const percent = this.data.value;

      if (percent >= 100) return "success";
      if (percent >= 80 && percent < 100) return "warning";
      if (percent < 80) return "error";

      return "error";
    },
  },
};
</script>
