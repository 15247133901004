<template>
  <v-data-table
    :headers="headers"
    :items="items"
    class="dashboard-dev__top100"
    :search="search"
  >
    <template #top>
      <v-text-field
        v-model="search"
        label="Search"
        prepend-icon="mdi-magnify"
        class="mx-4"
      ></v-text-field>
    </template>
    <template #[`item.phrasesCollected`]="{ item }">
      {{ item.phrasesCollected | localeNumber }}
    </template>
    <template v-slot:item.phrasesNeed="{ item }">
      {{ item.phrasesNeed | localeNumber }}
    </template>
    <template #[`item.delta`]="{ item }">
      <v-icon v-if="check_delta_error(item)" color="red" small
        >mdi-alert-circle-outline</v-icon
      >
      <v-icon v-else color="green" small
        >mdi-checkbox-marked-circle-outline</v-icon
      >
      <span class="top100__delta-value">{{ item.delta }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { TOP100_TABLE_DATA } from "@/utils/defaultData";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    search: null,
    headers: TOP100_TABLE_DATA.headers,
  }),
  methods: {
    check_delta_error(item) {
      const today12pm = this.$moment("12:00", "HH:mm");
      const today12am = this.$moment("23:59:59", "HH:mm:ss");
      const currentTime = this.$moment();
      const time_between_12pm_and_12pm =
        currentTime.isAfter(today12pm) && currentTime.isBefore(today12am);
      const equals = Number(item.phrasesCollected) === Number(item.phrasesNeed);
      return !equals || time_between_12pm_and_12pm;
    },
  },
};
</script>
