export default {
  data: () => ({
    localStorageId: "default-settings",
  }),
  created() {
    const localStorageSettings = localStorage.getItem(this.localStorageId);

    if (
      !["null", "undefined"].includes(localStorageSettings) &&
      localStorageSettings
    ) {
      this.initSettingsFromJsonString(localStorageSettings);
    }
  },
  watch: {
    settings: {
      deep: true,
      handler(settings) {
        localStorage.setItem(this.localStorageId, JSON.stringify(settings));
      },
    },
  },
  methods: {
    initSettingsFromJsonString(jsonString) {
      try {
        this.settings = JSON.parse(jsonString);
      } catch {
        console.error("Invalid settings json string");
      }
    },
  },
};
