import colors from "vuetify/es5/util/colors";

export default {
  data: () => ({
    smStatuses: {
      new: {
        color: "success",
        icon: "mdi-new-box",
      },
      "to do": {
        color: colors.blue.lighten3,
        icon: "mdi-checkbox-blank-badge-outline",
      },
      done: {
        color: "success",
        icon: "mdi-check-all",
      },
      "in progress": {
        color: "warning",
        icon: "mdi-progress-helper",
      },
      ta: {
        color: "primary",
        title: "Terms of reference",
        icon: "mdi-source-repository",
      },
      cw: {
        color: "primary",
        title: "Content writing",
        icon: "mdi-pen",
      },
      publishing: {
        color: "info",
        icon: "mdi-publish",
      },
      dev: {
        color: "info",
        icon: "mdi-dev-to",
      },
      "wait for indexation": {
        color: "warning",
        icon: "mdi-robot-outline",
      },
      "need review": {
        color: "warning",
        icon: "mdi-magnify",
      },
      "need approve": {
        color: "warning",
        icon: "mdi-crosshairs-question",
      },
      approved: {
        color: "success",
        icon: "mdi-eye-check",
      },
      proofreading: {
        color: "info",
        icon: "mdi-auto-fix",
      },
    },
  }),
  computed: {
    getStatusColor() {
      try {
        const status = this.data?.status;

        if (!status) return "error";

        if (typeof status === "string") {
          return this.smStatuses[status].color;
        }

        return null;
      } catch (error) {
        console.error("Error while getting status color", error);
      }
    },
    getStatusIcon() {
      try {
        const status = this.data?.status;

        if (!status) return "mdi-close";

        if (typeof status === "string") {
          return this.smStatuses[status].icon;
        }

        return null;
      } catch (error) {
        console.error("Error while getting status icon", error);
      }
    },
  },
};
