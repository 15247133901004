export default {
  methods: {
    bggc_getMinMax(items, colKey, isHighlightZero) {
      let cols = [];

      items.forEach((item) => {
        if (isNaN(Number(item[colKey])) || item[colKey] === null) return;

        cols.push(Number(item[colKey]));
      });

      if (!isHighlightZero) cols = cols.filter((v) => v !== 0);

      const min = Math.min(...cols);
      const max = Math.max(...cols);

      return { min, max, cols };
    },
    bggc_getBgColor(value, colKey, items, isHighlightZero) {
      if (
        isNaN(Number(value)) ||
        (value === 0 && !isHighlightZero) ||
        value === null
      ) {
        return `transparent`;
      }

      const { min, max } = this.bggc_getMinMax(items, colKey, isHighlightZero);

      const range = max - min;

      if (range === 0) {
        return `hsl(120 100% 50% / 50%)`;
      }

      const distanceFromMin = Number(value) - min;
      const percent = distanceFromMin / range;
      const hue = percent * 120;

      return `hsl(${hue} 100% 50% / 50%)`;
    },
  },
};
