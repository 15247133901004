<template>
  <v-dialog v-model="dialog" content-class="my-shadow--e2" width="420">
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2 pa-3">
        Add task
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <div class="text-body-2 mb-1">Type</div>
            <v-autocomplete
              v-model="form.type"
              hide-details
              dense
              outlined
              clearable
              placeholder="Type to search"
              :items="types"
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2 mb-1">Project from</div>
            <v-autocomplete
              v-model="form.project_from"
              hide-details
              dense
              outlined
              clearable
              item-text="name"
              item-value="id"
              placeholder="Type to search"
              :items="_$getProjects"
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2 mb-1">Project to</div>
            <v-autocomplete
              v-model="form.project_to"
              hide-details
              dense
              outlined
              clearable
              item-text="name"
              item-value="id"
              placeholder="Type to search"
              :items="_$getProjects"
            />
          </v-col>
          <v-col cols="12"></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          class="px-6 text-normal"
          large
          color="success"
          @click="handleAddTask"
          :loading="loadings.add"
        >
          Add <v-icon small right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import Project from "../../../mixins/Project";
import { BackgroundTasksApiService } from "../services/BackgroundTasksApiService";

const TYPES = [
  {
    text: "Main sync projects",
    value: "main_sync_projects",
  },
];

export default {
  mixins: [Dialog, Project],

  emits: ["added"],

  data: () => ({
    loadings: {
      add: false,
    },
    form: {
      type: TYPES[0]?.value,
      project_from: null,
      project_to: null,
    },
  }),

  computed: {
    types() {
      return TYPES;
    },
  },

  methods: {
    clearForm() {
      this.form = {
        type: TYPES[0]?.value,
        project_from: null,
        project_to: null,
      };
    },
    async handleAddTask() {
      try {
        this.loadings.add = true;

        const { data } = await BackgroundTasksApiService().addBackgroundTask(
          this.form
        );

        this.$emit("added", data?.task);

        this.clearForm();
        this.dialog = false;
        this.$message.notification({
          title: "Success",
          text: "Task successfully added to the queue.",
          type: "success",
        });
      } catch (e) {
        console.error("Error while adding task to the queue.", e);
      } finally {
        this.loadings.add = false;
      }
    },
  },
};
</script>
