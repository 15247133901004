var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":280,"transition":"slide-y-transition","offset-x":"","offset-y":"","content-class":"my-shadow--e3","max-height":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":_vm.notification_count,"value":_vm.notification_count > 0,"color":"pink","overlap":""}},[(_vm.notification_count > 0)?_c('v-icon',[_vm._v("mdi-bell-ring")]):_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"mx-auto styled-card--default",attrs:{"max-width":"400"}},[_c('v-slide-y-transition',[_c('v-card-title',{staticClass:"pa-2"},[_c('v-item-group',{staticClass:"my-group my-group--two",attrs:{"active-class":"primary--text"}},[_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":_vm.mode === 'all' ? 'primary' : ''},on:{"click":function($event){_vm.mode = 'all'}}},[_vm._v(" last ")]),_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":_vm.mode === 'new' ? 'primary' : ''},on:{"click":function($event){_vm.mode = 'new'}}},[_vm._v(" new ")])],1),_c('v-spacer'),_c('v-chip',{attrs:{"small":"","color":"transparent","label":""},on:{"click":_vm.handleMarkAllAsRead}},[_c('span',{staticClass:"pink--text"},[_vm._v("mark all read "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-eye-outline")])],1)])],1)],1),_c('v-divider'),(
        _vm.getFilteredNotificationItems.length > 0 &&
        !_vm.loadings.uploadNotifications
      )?[_c('v-slide-x-transition',{staticClass:"v-sheet hide-scrollbar theme--light v-list--dense v-list--nav v-list--two-line px-4",staticStyle:{"max-height":"400px","overflow":"scroll"},attrs:{"group":"","tag":"v-list"}},[_vm._l((_vm.getFilteredNotificationItems),function(item,i){return [_c('v-list-item',{key:`${i}-${item.title}`,scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-icon',{staticClass:"mr-4"},[_c('notification-icon',{attrs:{"notification":item}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex",class:{ 'pink--text': active }},[_vm._v(" "+_vm._s(item.content.title)+" "),_c('v-spacer'),_c('small',[_vm._v(" "+_vm._s(item.created_at)+" ")])],1),_c('v-list-item-subtitle',{staticStyle:{"margin-top":"4px","opacity":"0.8"},domProps:{"textContent":_vm._s(item.content.message)}}),(item.content.link)?_c('div',{staticClass:"mt-2"},[_c('v-chip',{attrs:{"label":"","outlined":""},on:{"click":function($event){return _vm.goTo(item.content.link)}}},[_vm._v(" Download file "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-download")])],1)],1):_vm._e()],1),(
                  item.viewed === 0 &&
                  _vm.notificationViewed.indexOf(item.id) === -1
                )?_c('v-list-item-action',[_c('v-chip',{attrs:{"color":"transparent","label":"","small":""},on:{"click":function($event){return _vm.setNotificationViewed(item, true)}}},[_vm._v(" Read "),(!_vm.loadings.setViewed.includes(item.id))?_c('v-icon',{attrs:{"small":"","label":"","right":"","color":"pink"}},[_vm._v(" mdi-bell ")]):_c('v-progress-circular',{staticClass:"ml-2",attrs:{"size":"16","width":"2","color":"pink","indeterminate":""}})],1)],1):_vm._e()]}}],null,true)}),(i !== _vm.getFilteredNotificationItems.length - 1)?_c('v-divider',{key:`${i}-${item.title}-divider`}):_vm._e()]})],2)]:(_vm.loadings.uploadNotifications)?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"150px"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"54","width":"2","color":"primary"}})],1):_c('v-alert',{staticClass:"mb-0 text-center"},[_c('div',{staticClass:"my-6"}),_c('v-icon',{staticStyle:{"opacity":"0.5"},attrs:{"size":"60"}},[_vm._v(" mdi-emoticon-cry-outline ")]),_c('div',{staticClass:"my-2"},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("No new notifications")])])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-chip',{attrs:{"outlined":"","label":"","small":"","title":"Upload new notifications","disabled":_vm.loadings.uploadNotifications},on:{"click":_vm.handleUpdateNewNotifications}},[_vm._v(" Upload new notifications "),(!_vm.loadings.uploadNotifications)?_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-refresh")]):_c('v-progress-circular',{staticClass:"ml-2",attrs:{"size":"14","width":"2","indeterminate":""}})],1),_c('v-spacer')],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }