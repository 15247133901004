<template>
  <v-container fluid class="GPM-entity">
    <v-container fluid>
      <v-alert
        color="red"
        dark
        rounded="lg"
        icon="mdi-alert-circle"
        dense
        v-if="header.status_id === 404"
      >
        Domain was rejected.
      </v-alert>
      <v-alert
        type="info"
        rounded="lg"
        icon="mdi-sign-text"
        dense
        text
        v-if="header.status_id === 400"
      >
        Domain was reserved
      </v-alert>
      <v-alert
        type="warning"
        rounded="lg"
        dense
        icon="mdi-volume-mute"
        text
        v-if="header.status_id === 429"
      >
        Domain not responding
      </v-alert>
      <gpm-entity-header :header="header" />
      <decline-donor-dialog
        v-model="decline_dialog"
        @declined="handleDonorDeclined"
      />
      <bad-donor-status-dialog
        v-model="bad_donor_dialog"
        :tags="bad_donor_statuses"
        :filters-pending="loadings.badDonorStatus"
        @callback="handleAfterSendDonorToBad"
      />
      <v-row v-if="header.performer !== null">
        <v-col
          lg="4"
          md="4"
          sm="12"
          cols="12"
          class="GPM-entity__control_and_info"
        >
          <div class="control_and_info__wrapper">
            <div class="control_and_info__label">Actions</div>
            <div class="control_and_info__buttons">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :loading="buttons_loading"
                    class="mr-2 mb-2 back-btn"
                    @click="goBack"
                  >
                    <v-icon style="transform: rotate(180deg)"
                      >mdi-exit-to-app</v-icon
                    >
                  </v-btn>
                </template>
                <span>Back to guest post list</span>
              </v-tooltip>
              <v-tooltip bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :loading="buttons_loading"
                    class="mr-2 mb-2"
                    @click="openChat"
                  >
                    <v-badge
                      v-if="unreadeMessagesCount > 0"
                      :content="unreadeMessagesCount"
                      color="error"
                    >
                      <v-icon>mdi-forum-outline</v-icon>
                    </v-badge>
                    <v-badge
                      v-else-if="allMessagesCount > 0"
                      :content="allMessagesCount"
                      color="grey"
                    >
                      <v-icon>mdi-forum-outline</v-icon>
                    </v-badge>
                    <v-icon v-else>mdi-forum-outline</v-icon>
                  </v-btn>
                </template>
                <span>Chat</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :loading="buttons_loading"
                    class="mr-2 mb-2"
                    @click="openHistory"
                  >
                    <v-icon>mdi-history</v-icon>
                  </v-btn>
                </template>
                <span>History</span>
              </v-tooltip>
              <v-tooltip bottom v-if="isGuestPost">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :loading="buttons_loading || loadings.sync"
                    class="mr-2 mb-2"
                    :color="syncSuccess ? 'success' : ''"
                    @click="syncFields"
                  >
                    <v-icon>{{
                      syncSuccess
                        ? "mdi-checkbox-marked-circle-outline"
                        : "mdi-sync"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ syncSuccess ? "Success! 👍" : "Sync Fields" }}
                </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :loading="buttons_loading"
                    class="mr-2 mb-2"
                    @click="openEditDialog()"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Fields Editor</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :loading="buttons_loading"
                    class="mr-2 mb-2"
                    elevation="0"
                    @click="handleOpenFieldsDialog"
                  >
                    <v-icon>mdi-archive-clock-outline</v-icon>
                  </v-btn>
                </template>
                <span>Fields History</span>
              </v-tooltip>
              <v-tooltip bottom v-if="isGuestPost">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :loading="buttons_loading"
                    class="mr-2 mb-2"
                    @click="reserveDomain('reserved')"
                  >
                    <v-icon>mdi-basket-outline</v-icon>
                  </v-btn>
                </template>
                <span>Reserve Domain</span>
              </v-tooltip>
              <v-menu
                v-model="moveToModel"
                bottom
                nudge-left="50%"
                rounded="lg"
                nudge-bottom="50%"
                :close-on-content-click="false"
                content-class="my-shadow--e3"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :loading="buttons_loading"
                    class="mr-2 mb-2"
                    @click="fetchAnchorPlans"
                  >
                    <v-icon>mdi-anchor</v-icon>
                  </v-btn>
                </template>
                <v-card class="styled-card--default">
                  <v-card-title> Move to </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-autocomplete
                      v-model="selectedAnchorPlanTask"
                      :items="anchorPlanTasksItems"
                      :loading="loadings.anchorPlans"
                      outlined
                      hide-details
                      dense
                      :menu-props="menuProps"
                      label="Anchor plan tasks"
                    ></v-autocomplete>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      :loading="loadings.moveTo"
                      color="success"
                      block
                      @click="moveAchorPlans"
                      >Move</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
              <v-btn
                class="transparent"
                text
                @click="actionsDialogModel = true"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </div>
          </div>
          <div
            v-if="fields.info.length !== 0"
            class="control_and_info__info-fields"
          >
            <v-card
              v-if="
                fields.info.filter((v) => v.type !== 'ah_top_pages').length !==
                0
              "
              outlined
              flat
              elevation="0"
              class="rounded-lg"
            >
              <template v-for="(item, idx) in fields.info">
                <info-field-manager :key="`field-${item.id}`" :item="item" />
                <v-divider
                  v-if="idx !== fields.info.length - 1"
                  :key="item.id"
                />
              </template>
              <v-row v-if="recheckProblemsData.problems.length !== 0">
                <v-col cols="12">
                  <v-alert
                    border="right"
                    dense
                    text
                    type="warning"
                    icon="mdi-alert"
                    class="GPM-entity__recheck-problems"
                  >
                    <div style="word-break: break-word">
                      {{ recheckProblemsData.comment }}
                    </div>
                    <div>
                      <ul>
                        <li
                          v-for="(problem, i) in recheckProblemsData.problems"
                          :key="`problem-${i}`"
                        >
                          {{ problem }}
                        </li>
                      </ul>
                    </div>
                  </v-alert>
                </v-col>
              </v-row>
            </v-card>
            <div
              class="mt-2"
              v-if="
                fields.info.filter((v) => v.type === 'ah_top_pages').length !==
                0
              "
            >
              <AhTopPages
                :custom-domain="
                  fields.info.filter((v) => v.type === 'ah_top_pages')[0].value
                    .value
                "
                assignment="gtt"
              />
            </div>
          </div>
        </v-col>
        <v-col
          lg="8"
          md="8"
          sm="12"
          cols="12"
          class="GPM-entity__fields border"
        >
          <v-row dense>
            <v-col class="d-flex" style="gap: 0.5rem">
              <v-btn
                :loading="buttons_loading || fields_loading"
                v-for="item in buttons.recheck"
                :key="`recheck-button-${item.id}`"
                color="warning"
                class="text-normal"
                @click="changeStatus(item.id, 'recheck', true)"
              >
                {{ item.title }}
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-end" style="gap: 0.5rem">
              <v-btn
                :loading="buttons_loading || fields_loading"
                v-for="item in buttons.approve"
                :key="`approve-button-${item.id}`"
                color="success"
                class="text-normal"
                @click="changeStatus(item.id, 'approve')"
              >
                {{ item.title }}
              </v-btn>
            </v-col>
          </v-row>
          <v-card
            v-if="fieldsContent.length !== 0"
            class="mt-4 mb-12 pa-0"
            elevation="0"
            :loading="fields_loading"
          >
            <v-form ref="bodyForm">
              <v-row dense>
                <v-col
                  v-for="item in fieldsContent"
                  :key="`content-field-${item.id}`"
                  cols="12"
                >
                  <ContentFieldManager
                    :item="item"
                    :header="header"
                    type="default"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-alert type="warning" text>
            Performer not selected for Anchor Plan Task, please contact SEO ({{
              header.creator
            }})!
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="recheck_dialog.dialog"
      max-width="600px"
      transition="slide-y-transition"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">What is the problem?</span>
        </v-card-title>
        <v-card-text>
          <v-combobox
            v-model="recheck_problems_select"
            :items="recheck_problems"
            multiple
            outlined
            hide-details
            dense
            label="Problem"
            placeholder="Select a problem"
            small-chips
          ></v-combobox>
        </v-card-text>
        <v-card-text>
          <v-textarea
            v-model="recheck_problems_text"
            hide-details
            outlined
            label="Comment"
            placeholder="Enter message"
            dense
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="recheck_dialog.dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="recheck_problems_select.length === 0"
            @click="changeStatus(recheck_dialog.id, 'recheck')"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="edit_dialog"
      persistent
      scrollable
      max-width="800px"
      content-class="my-shadow--e4"
      transition="slide-y-transition"
    >
      <v-card class="styled-card--default">
        <v-card-title> Fields Editor </v-card-title>
        <v-divider />
        <v-card-text>
          <template v-if="!fields_loading">
            <v-form
              class="mt-2"
              ref="form"
              lazy-validation
              v-if="fieldsAll.length !== 0"
            >
              <v-list-item
                v-for="item in fieldsAll"
                :key="`content-field-all-${item.id}`"
              >
                <v-list-item-content>
                  <ContentFieldManager
                    :item="item"
                    :header="header"
                    type="editor"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-form>
          </template>
          <template v-else>
            <fields-editor-skeleton />
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-chip
            outlined
            label
            class="px-4 py-2 mr-2"
            style="height: auto"
            @click="edit_dialog = false"
          >
            Close
          </v-chip>
          <v-chip
            label
            class="px-8 py-2"
            style="height: auto"
            color="success"
            @click="saveFields"
          >
            Save
            <v-progress-circular
              v-if="loading"
              indeterminate
              size="16"
              width="2"
              class="ml-2"
            />
          </v-chip>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="chat.view"
      content-class="GPM-entity__chat"
      width="600"
      transition="slide-y-transition"
      scrollable
    >
      <v-card class="styled-card--default">
        <v-card-title> Chat </v-card-title>
        <v-divider />
        <v-card-text style="min-height: 260px">
          <v-list id="chat-list" style="max-height: 350px; overflow: auto">
            <template v-if="loadings.chat">
              <chat-skeleton />
            </template>
            <template v-else-if="chat.messages.length > 0">
              <v-list-item
                v-for="message in chat.messages"
                :key="`message-${message.id}`"
                class="chat__message"
              >
                <v-list-item-content>
                  <div
                    :class="
                      message.user === userId
                        ? 'text-right from-me'
                        : 'text-left from-other'
                    "
                  >
                    <div
                      class="d-inline-block grey lighten-3 rounded pa-2 message relative"
                      style="max-width: 350px"
                      v-html="message.message"
                    ></div>
                    <br />
                    <small>{{ message.date }}</small>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item
                style="flex-direction: column"
                class="justify-end hide-list-after"
              >
                <div style="max-width: 250px; margin: auto">
                  <no-messages-placeholder-illustration />
                </div>
                <div
                  style="
                    max-width: 320px;
                    text-align: center;
                    font-size: 14px;
                    opacity: 0.8;
                  "
                >
                  There are no messages in this chat room yet. You can be the
                  first to leave a message
                </div>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions style="padding-bottom: 16px">
          <v-textarea
            v-model="chat.message"
            outlined
            dense
            style="border-radius: 12px"
            height="75"
            no-resize
            hide-details
            placeholder="Write a message..."
            append-icon="mdi-send"
            :loading="chat.send"
            @click:append="sendMessage"
            @keydown.enter.exact.prevent="sendMessage"
            @keydown.enter.shift.exact.prevent="chat.message += '\n'"
          ></v-textarea>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="history.view"
      width="600"
      content-class="my-shadow--e4"
      scrollable
      transition="slide-y-transition"
    >
      <v-card class="styled-card--default">
        <v-card-title>
          History
          <v-spacer />
          <v-btn icon @click="history.view = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <div class="d-flex flex-column" style="gap: 32px">
            <template v-if="loadings.historyItems">
              <history-items-skeleton />
            </template>
            <template v-else>
              <template
                v-if="
                  !loadings.historyItems &&
                  history &&
                  Array.isArray(history.items) &&
                  history.items.length > 0
                "
              >
                <div
                  v-for="item in history.items"
                  :key="`history-${item.id}`"
                  class="d-flex"
                >
                  <div>
                    <div>
                      <small>{{ item.date }}</small>
                    </div>
                    <div>
                      <b style="font-size: 18px">{{ item.name }}</b>
                    </div>
                    <div>
                      {{ item.email }}
                    </div>
                  </div>
                  <v-spacer />
                  <div class="d-flex align-center">
                    <v-btn icon @click="viewHistory(item.id)">
                      <v-icon>mdi-login-variant</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="placeholder-text">Empty...</div>
              </template>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <fields-history-dialog
      :model-value="fieldsHistory.dialog"
      @update:modelValue="fieldsHistory.dialog = $event"
      :loading="loadings.fieldsHistory"
      :fields-history="fieldsHistory"
    />
    <agree-dialog
      :model-value="agreeDialogModel"
      @update:modelValue="agreeDialogModel = $event"
    >
      <template #title> Are you sure? </template>
      <template #default>
        <p v-html="agreeDialogData.text" class="text-body-1"></p>
      </template>
      <template #actions>
        <v-row dense no-gutters>
          <v-col cols="12" class="d-flex justify-end" style="gap: 0.5rem">
            <v-btn @click="disagree" text large class="px-4">
              {{ agreeDialogData.cancelText }}
            </v-btn>
            <v-btn @click="agree" outlined large class="px-4">
              {{ agreeDialogData.agreeText }}</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </agree-dialog>
    <v-dialog
      v-model="actionsDialogModel"
      content-class="actions-model remove-dialog-shadow pa-4"
      max-width="500px"
    >
      <v-tooltip v-if="header.status_id === 404" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :loading="loadings.reviewDonor"
            class="mr-2 mb-2"
            color="error"
            @click="handleReviewDonor('You want to revive the domain.')"
          >
            <v-icon>mdi-heart-pulse</v-icon>
          </v-btn>
        </template>
        <span>Revive Donor</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isGuestPost">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :loading="buttons_loading"
            class="mr-2 mb-2"
            color="warning"
            @click="reserveDomain('silent')"
          >
            <v-icon>mdi-ear-hearing-off</v-icon>
          </v-btn>
        </template>
        <span>Silent Domain</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :loading="buttons_loading"
            class="mr-2 mb-2"
            color="error"
            @click="bad_donor_dialog = true"
          >
            <v-icon>mdi-emoticon-sad-outline</v-icon>
          </v-btn>
        </template>
        <span>Bad Donor Status</span>
      </v-tooltip>
      <v-tooltip v-if="header.status_id !== 404" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :loading="buttons_loading"
            class="mr-2 mb-2"
            color="error"
            @click="decline_dialog = true"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Decline Donor</span>
      </v-tooltip>
      <v-tooltip v-if="allowedReset" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :loading="buttons_loading"
            class="mr-2 mb-2"
            color="error"
            @click="resetData"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>Reset Data</span>
      </v-tooltip>
    </v-dialog>
  </v-container>
</template>

<script>
import ContentFieldManager from "@/components/Gpm/Fields/ContentFieldManager";
import InfoFieldManager from "@/components/Gpm/Fields/InfoFieldManager";
import AhTopPages from "@/components/ViewInfoBySite/Children/AhTopPages";
import eventPipe from "@/events/eventPipe";
import GpmEntityHeader from "@/components/Gpm/ViewEntity/GpmEntityHeader";
import FieldsEditorSkeleton from "@/components/Gpm/ViewEntity/FieldsEditorSkeleton";
import AgreeDialogMixin from "@/mixins/AgreeDialogMixin";
import AgreeDialog from "@/components/UI/AgreeDialog";
import ChatSkeleton from "@/components/Gpm/ViewEntity/ChatSkeleton";
import HistoryItemsSkeleton from "@/components/Gpm/ViewEntity/HistoryItemsSkeleton";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import CopyComponentId from "@/mixins/copyComponentId";
import service from "../../plugins/service";
import NoMessagesPlaceholderIllustration from "../UI/Icons/NoMessagesPlaceholderIllustration";
import FieldsHistoryDialog from "@/components/Gpm/ViewEntity/FieldsHistoryDialog.vue";
import DeclineDonorDialog from "./components/DeclineDonorDialog.vue";
import { gpmDonorApiService } from "./services/GpmDonorApiService";
import BadDonorStatusDialog from "./components/BadDonorStatusDialog.vue";

export default {
  name: "GpmViewEntity",
  components: {
    BadDonorStatusDialog,
    DeclineDonorDialog,
    FieldsHistoryDialog,
    NoMessagesPlaceholderIllustration,
    HistoryItemsSkeleton,
    ChatSkeleton,
    AgreeDialog,
    FieldsEditorSkeleton,
    GpmEntityHeader,
    ContentFieldManager,
    InfoFieldManager,
    AhTopPages,
  },
  mixins: [AgreeDialogMixin, CopyComponentId],
  provide() {
    return {
      headerData: () => this.header,
    };
  },
  data: () => ({
    // Services
    gpmDonorApiService: null,

    // State
    actionsDialogModel: false,
    newStatusItem: "",
    syncSuccess: false,
    moveToModel: false,
    loadings: {
      fieldsHistory: false,
      reviewDonor: false,
      moveTo: false,
      anchorPlans: false,
      chat: false,
      badDonorStatus: false,
      sync: false,
      historyItems: false,
    },
    messages: [],
    connectionWS: undefined,
    chat: {
      send: false,
      view: false,
      loading: false,
      message: "",
      messages: [],
    },
    loading: false,
    fieldsHistory: {
      dialog: false,
      items: [],
    },
    history: {
      selected: null,
      view: false,
      items: [],
    },
    header: {
      domain: "",
      stage: 0,
      type_id: 0,
      status_name: "",
      main_status: "",
      assigned: "",
      time_on_stage: "",
      outsources: [],
      performer: "",
      creator: "",
    },
    bad_donor_status: [],
    unreadeMessagesCount: 0,
    allMessagesCount: 0,
    bad_donor_statuses: [],
    edit_dialog: false,
    recheck_dialog: {
      id: null,
      dialog: false,
    },
    bad_donor_dialog: false,
    decline_dialog: false,
    fields_loading: false,
    buttons_loading: false,
    fields: {
      info: [],
      content: [],
      all: [],
    },
    buttons: {
      approve: [],
      recheck: [],
    },
    recheck_problems: [],
    recheckProblemsData: {
      comment: null,
      problems: [],
    },
    copyComponentId: "aslkdj1j2h412-94iasd",
    recheck_problems_select: [],
    recheck_problems_text: "",
    agreeDialogModel: false,
    anchorPlanTasksItems: null,
    selectedAnchorPlanTask: null,
    menuProps: DEFAULT_MENU_PROPS,
    agreeDialogData: {
      title: "Are you sure?",
      text: "",
      cancelText: "Cancel",
      agreeText: "Yes",
    },
  }),
  computed: {
    haveUnreadedMessage() {
      return true;
    },
    isGuestPost() {
      return this.header.type_id === 1;
    },
    userId() {
      return this.$store.state.auth.user.id;
    },
    allowedReset() {
      if (!this.$store.state.base.permissions) return false;

      const permissions = this.$store.state.base.permissions;

      const permissionForThisPage = permissions.find(
        (v) => v.route === this.$route.name
      );

      if (!permissionForThisPage) return false;

      return permissionForThisPage.permission === "a";
    },
    fieldsContent() {
      return this.fields.content;
    },
    fieldsAll() {
      return this.fields.all;
    },
  },
  methods: {
    handleConfirmRecheckForm(payload) {
      this.changeStatus(payload.id, payload.action);
    },
    handleAfterSendDonorToBad() {
      this.refreshPageData();
      this.bad_donor_dialog = false;
      this.actionsDialogModel = false;
    },
    handleDonorDeclined() {
      this.refreshPageData();
      this.decline_dialog = false;
      this.actionsDialogModel = false;
    },
    refreshPageData() {
      this.fetchFields();
      this.fetchButtons();
      this.fetchHeader();
    },
    async moveAchorPlans() {
      this.moveToModel = false;
      this.loadings.moveTo = true;
      const payload = {
        entity: parseInt(this.$route.params.id),
        task: this.selectedAnchorPlanTask,
      };
      const resp = await this.$store.dispatch(
        "reservedDomains/moveTask",
        payload
      );
      this.loadings.moveTo = false;
      if (resp && resp === true) {
        location.reload();
      }
    },
    async fetchAnchorPlans() {
      if (this.anchorPlanTasksItems !== null) return;
      this.loadings.anchorPlans = true;
      let configs = {
        params: {
          projectID: this.$store.state.project.active_project,
          relevance: 3,
          type: 1,
        },
      };
      const data = await this.$store.dispatch(
        "reservedDomains/getAnchorPlanTasks",
        configs
      );
      if (data) {
        this.anchorPlanTasksItems = data.items;
      }
      this.loadings.anchorPlans = false;
    },
    handleDeleteItem(event, item) {
      event.preventDefault();
      event.stopPropagation();

      this.bad_donor_statuses = this.bad_donor_statuses.filter(
        (i) => i.id !== item.id
      );

      this.updateStatusItems();
    },
    handleAddNewStatusItem() {
      if (this.newStatusItem === "" || !this.newStatusItem) {
        return;
      }

      const biggerId =
        Math.max(...this.bad_donor_statuses.map((item) => item.id)) || 0;

      const newStatusItem = {
        id: biggerId + 1,
        name: this.newStatusItem,
      };

      this.bad_donor_statuses.push(newStatusItem);

      this.newStatusItem = null;

      this.updateStatusItems(newStatusItem);
    },
    async updateStatusItems() {
      try {
        const url = "/gpm/admin/bad-donor-status";

        const config = {
          params: {
            entityID: this.$route.params.id,
          },
        };

        const payload = {
          items: this.bad_donor_statuses,
        };

        await service.post(url, payload, true, config);
      } catch (e) {
        console.error("Error while updating status items,", e);
      }
    },
    async handleOpenFieldsDialog() {
      this.fieldsHistory.dialog = true;
      if (this.fieldsHistory.items.length === 0) {
        this.fetchFieldsHistory();
      }
    },
    saveFields() {
      try {
        if (this.$refs.form && !this.$refs.form.validate()) {
          this.$message.notification({
            title: "Validation",
            text: "Validation conditions are not met.",
            type: "error",
            duration: 6000,
          });
          return;
        }

        this.changeStatus(this.header.status_id, "update_fields");
      } catch (e) {
        console.error("Error while saving fields.", e);
      }
    },
    async openEditDialog() {
      try {
        this.fields.all = [];
        const url = "/gpm/entity-view/get-fields";
        const config = {
          params: {
            entityID: this.$route.params.id,
            type: "all",
          },
        };

        this.edit_dialog = true;
        this.fields_loading = true;

        const response = await service.get(url, true, config);

        if (response) this.fields.all = response.data.data.fields;
      } catch (e) {
        console.error("Error while loading edit dialog data.", e);
      } finally {
        this.fields_loading = false;
      }
    },
    async syncFields() {
      try {
        const url = `${this.$store.state.server_url}/gpm/entity-view/get-fields-sync`;
        const config = {
          params: {
            entityID: this.$route.params.id,
          },
        };

        this.loadings.sync = true;

        const response = await service.get(url, config);

        if (response) {
          await this.initializeFieldsData(response.data.data);

          this.$forceUpdate();

          this.switchSyncSuccess();
        }

        this.switchSyncSuccess();
      } catch (e) {
        console.error("Error while syncing fields.", e);
      } finally {
        this.loadings.sync = false;
      }
    },
    initializeFieldsData(items) {
      return new Promise((resolve, reject) => {
        try {
          const fields_content = this.fields.content.map((v) => {
            if (items[v.id] !== undefined) {
              v.value = items[v.id];
            }
            return v;
          });
          this.fields.content = [];

          setTimeout(() => {
            this.fields.content = fields_content;
            resolve();
          }, 100);
        } catch (e) {
          console.error("Error while initializing fields data.", e);
          reject();
        }
      });
    },
    switchSyncSuccess() {
      this.syncSuccess = true;
      setTimeout(() => (this.syncSuccess = false), 2500);
    },
    goBack() {
      this.$router.push({ name: "gpm.main-dashboard" });
    },
    viewHistory(id) {
      this.history.selected = id;

      this.fetchHeader();
      this.fetchFields();
    },
    async openChat() {
      await this.readMessage();
      this.chat.view = true;
      this.getMessages(true);
    },
    openHistory() {
      this.history.view = true;
      this.getHistory();
    },
    scrollBottom(selector) {
      setTimeout(function () {
        let container = document.querySelector(selector);
        if (!container) return;
        container.scrollTop = container.scrollHeight;
      }, 100);
    },
    async readMessage() {
      let url = "/gpm/chat/read-messages";

      let config = {
        params: {
          entityID: this.$route.params.id,
        },
      };

      await service.get(url, true, config);
    },
    async getMessages(setLoading = false) {
      try {
        if (setLoading) this.loadings.chat = true;

        const url = "/gpm/chat/get-messages";
        const config = {
          params: {
            entityID: this.$route.params.id,
          },
        };

        const response = await service.get(url, true, config);

        if (response) {
          this.chat.messages = response.data.items;
          this.unreadeMessagesCount = this.countUnreadedMessages(
            response.data.items
          );
          this.allMessagesCount = response.data.items.length;

          this.scrollBottom("#chat-list");
        }
      } catch (e) {
        console.error("Error while geting message.", e);
        throw e;
      } finally {
        this.loadings.chat = false;
      }
    },
    countUnreadedMessages(items) {
      let count = 0;

      if (!items) return count;

      items.forEach((v) => (v.read === 0 ? count++ : null));

      return count;
    },
    async getHistory() {
      try {
        const url = "/gpm/entity-view/get-history";
        const config = {
          params: {
            entityID: this.$route.params.id,
          },
        };

        const response = await service.get(url, true, config);

        if (response) {
          this.history.items = response.data.items;
          this.scrollBottom("#history-list");
        }
        this.loadings.historyItems = true;
      } catch (e) {
        console.error("Error while loading history.", e);
      } finally {
        this.loadings.historyItems = false;
      }
    },
    sendMessageEnter(event) {
      if (event.keyCode !== undefined && event.keyCode === 13) {
        this.sendMessage();
      }
    },
    async sendMessage() {
      try {
        let url = "/gpm/chat/send-message";

        this.chat.send = true;

        let configs = {
          params: {
            entityID: this.$route.params.id,
          },
        };

        await service.post(url, { message: this.chat.message }, true, configs);

        this.getMessages();
        this.chat.message = "";
      } catch (e) {
        console.error("Error when send a message.", e);
      } finally {
        this.chat.send = false;
      }
    },
    async resetData() {
      try {
        const text = "You want to reset a domain.";
        if (!(await this.openAgreeDialog(text))) return;

        const url = "/gpm/entity-view/reset-data";
        const config = {
          params: {
            entityID: this.$route.params.id,
          },
        };

        await service.post(url, {}, true, config);

        this.fetchFields();
        this.fetchButtons();
        this.fetchHeader();
      } catch (e) {
        console.error("Error while trying to reset data.", e);
      }
    },
    async fetchHeader() {
      try {
        this.header = {
          domain: "",
          stage: 0,
          type_id: 0,
          status_name: "",
          main_status: "",
          assigned: "",
          time_on_stage: "",
          outsources: [],
        };
        const url = "/gpm/entity-view/get-header";
        const config = {
          params: {
            entityID: this.$route.params.id,
            historyID: this.history.selected,
          },
        };

        this.loading = true;

        const response = await service.get(url, true, config);

        if (response) {
          this.bad_donor_statuses = response.data.header.bad_donor_statuses;
          this.header = response.data.header;
        }
      } catch (e) {
        console.error("Error while trying load header data.", e);
        throw e;
      } finally {
        this.loading = false;
      }
    },
    async handleReviewDonor(message) {
      if (!(await this.openAgreeDialog(message, { agreeText: "Review" })))
        return;

      this.loadings.reviewDonor = true;

      await this.gpmDonorApiService.revive(this.$route.params.id);

      this.loadings.reviewDonor = false;

      this.fetchFields();
      this.fetchButtons();
      this.fetchHeader();
      this.actionsDialogModel = false;
    },
    async openAgreeDialog(text, config) {
      this.agreeDialogData.text = text;
      this.agreeDialogData.cancelText = config?.cancelText || "Cancel";
      this.agreeDialogData.agreeText = config?.agreeText || "Yes";

      return await this.open();
    },
    async reserveDomain(type) {
      try {
        const text = `You want to add a domain to the <b>${type}</b>.`;
        if (!(await this.openAgreeDialog(text, { agreeText: "Add" }))) return;

        const url = "/gpm/entity-view/reserve-donor";
        const config = {
          params: {
            entityID: this.$route.params.id,
            type: type,
          },
        };

        this.buttons_loading = true;

        await service.post(url, {}, true, config);

        this.fetchFields();
        this.fetchButtons();
        this.fetchHeader();
      } catch (e) {
        console.error("Error while trying to reserve domain.", e);
      } finally {
        this.buttons_loading = false;
      }
    },
    async fetchButtons() {
      try {
        this.buttons = {
          approve: [],
          recheck: [],
        };

        const url = "/gpm/entity-view/get-buttons";
        const config = {
          params: {
            entityID: this.$route.params.id,
          },
        };

        this.buttons_loading = true;

        const response = await service.get(url, true, config);

        if (response) this.buttons = response.data.buttons;
      } catch (e) {
        console.error("Error while loading buttons list.", e);
        throw e;
      } finally {
        this.buttons_loading = false;
      }
    },
    processFieldsData(fieldsData) {
      try {
        this.recheckProblemsData = fieldsData.data.recheck_problems;
        this.fields.content = fieldsData.data.fields.content;
        this.fields.info = fieldsData.data.fields.info;

        if (
          this.fields.info.filter((v) => v.type === "ah_top_pages").length !== 0
        ) {
          this.fetchAhTopPages(
            this.fields.info.filter((v) => v.type === "ah_top_pages")[0].value
              .value
          );
        }
      } catch (e) {
        console.error("Error while processing fields data.", e);
      }
    },
    async fetchFields() {
      try {
        this.$store.state.Gpm.field_values["default"] = {};
        this.fields.content = [];
        this.fields.info = [];

        const url = "/gpm/entity-view/get-fields";
        const config = {
          params: {
            entityID: this.$route.params.id,
            historyID: this.history.selected,
          },
        };

        this.fields_loading = true;

        const response = await service.get(url, true, config);

        if (response) this.processFieldsData(response.data);
      } catch (e) {
        console.error("Error while loading fields data.", e);
        throw e;
      } finally {
        this.fields_loading = false;
      }
    },
    connectToWS() {
      this.connectionWS = new WebSocket("ws://88.99.5.25:8081");

      this.connectionWS.onmessage = (event) => {
        eventPipe.$emit("vibs:got", JSON.parse(event.data));
        this.messages.push(event);
      };

      this.connectionWS.onopen = function () {};
    },
    createWs() {
      eventPipe.$on("vibs:send", (args) => {
        this.connectionWS.send(args.json);
      });
    },
    fetchAhTopPages(domain) {
      this.connectToWS();
      this.createWs();

      eventPipe.$emit("vibs:send", {
        json: JSON.stringify({
          domain: domain,
          type: "ah:top_pages",
        }),
      });

      return "";
    },
    processRecheckProblems(data, id) {
      this.recheck_problems = data.items;

      if (this.recheck_problems.length !== 0) {
        return (this.recheck_dialog = {
          id,
          dialog: true,
        });
      }

      this.changeStatus(id, "recheck");
    },
    async fetchRecheckProblems(id) {
      try {
        const url = "/gpm/entity-view/recheck-problems";
        const config = {
          params: {
            statusID: this.header.status_id,
            recheckStatusID: id,
          },
        };

        const response = await service.get(url, true, config);

        if (response) this.processRecheckProblems(response.data, id);
      } catch (e) {
        console.error("Error while loading recheck problems.", e);
      }
    },
    async fetchFieldsHistory() {
      try {
        const url = "/gpm/entity-view/get-history";
        const config = {
          params: {
            entityID: this.$route.params.id,
          },
        };

        this.loadings.fieldsHistory = true;

        const response = await service.get(url, true, config);

        if (response) this.fieldsHistory.items = response.data.items;
      } catch (e) {
        console.error("Error while loading fields history.", e);
      } finally {
        this.loadings.fieldsHistory = false;
      }
    },
    async changeStatus(id, type, confirm = false) {
      try {
        if (this.$refs.bodyForm && !this.$refs.bodyForm.validate()) return;

        if (type === "recheck" && confirm) return this.fetchRecheckProblems(id);

        this.loading = true;
        this.buttons_loading = true;

        const url = "/gpm/entity-view/change-status";

        const config = {
          params: {
            entityID: this.$route.params.id,
            statusID: id,
            type: type,
          },
        };

        const payload = {
          fields:
            this.$store.state.Gpm.field_values[
              type === "update_fields" ? "editor" : "default"
            ],
          recheck_problems: this.recheck_problems_select,
          recheck_problems_comment: this.recheck_problems_text,
        };

        const response = await service.post(url, payload, true, config);

        if (response) {
          if (response.data && !response.data.success) {
            this.$message.notification({
              title: "Something wrong",
              text: response.data.error || "Try again later.",
              type: "error",
            });
          }

          this.fetchFields();
          this.fetchButtons();
          this.fetchHeader();
        }
      } catch (e) {
        console.error("Error while changing status.", e);
      } finally {
        this.loading = false;
        this.edit_dialog = false;
        this.recheck_dialog.dialog = false;
      }
    },
  },
  created() {
    this.gpmDonorApiService = gpmDonorApiService();

    this.fetchFields();
    this.fetchButtons();
    this.fetchHeader();
    this.getMessages();
  },
};
</script>

<style>
.hide-list-after:after {
  display: none;
}
</style>
