<template>
  <td>
    <v-sparkline
        :value="data"
        width="175"
        :color="getColor"
        smooth
        auto-draw
    />
  </td>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    getColor() {
      if(this.data[0] < this.data[this.data.length - 1]) {
        return 'green'
      }
      return 'red'
    }
  }
}
</script>