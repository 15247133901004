<template>
  <td>
    <span style="white-space: nowrap" :class="`delta-td ${getColor}`">
      <template v-if="data.value === null && data.delta === null"> - </template>
      <template v-else-if="data.value === null && data.delta">
        <span> 0 </span>
        <sup>
          {{ data.delta }}
        </sup>
      </template>
      <template v-else-if="data.value === 0 && data.delta === 0"> - </template>
      <template v-else-if="data.value === null && data.delta === 0">
        -
      </template>
      <template v-else-if="data.value === 0 && data.delta === null">
        -
      </template>
      <template
        v-else-if="
          data.value !== null && (data.delta === null || data.delta === 0)
        "
      >
        <span>
          {{ data.value ? data.value : "-" }}
        </span>
      </template>
      <template v-else>
        <span>
          {{ data.value ? data.value : "-" }}
        </span>
        <sup>
          {{ data.delta ? data.delta : "-" }}
        </sup>
      </template>
    </span>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getColor() {
      const value = this.data.delta;
      if (value === 0) {
        return "";
      }
      if (value > 0) {
        return "green-sup";
      }
      return "red-sup";
    },
  },
};
</script>
