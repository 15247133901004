<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="6">
        <v-alert icon="mdi-calendar-blank" prominent text type="info">
          <v-row align="center">
            <v-col class="grow">
              <h3 class="headline">Short term</h3>
              <div>
                Nunc nonummy metus. Nunc interdum lacus sit amet orci. Nullam
                dictum felis eu pede mollis pretium. Cras id dui.
              </div>
            </v-col>
            <v-col class="shrink">
              <v-btn
                color="info"
                outlined
                @click="
                  short_term.dialog = true;
                  edit_dialog = false;
                "
                >Create</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col cols="12" md="6">
        <v-alert icon="mdi-calendar-sync" prominent text type="info">
          <v-row align="center">
            <v-col class="grow">
              <h3 class="headline">Long term</h3>
              <div>
                Nunc nonummy metus. Nunc interdum lacus sit amet orci. Nullam
                dictum felis eu pede mollis pretium. Cras id dui.
              </div>
            </v-col>
            <v-col class="shrink">
              <v-btn color="info" outlined>Create</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-snackbar v-model="snackbar_export_pro" timeout="5000" color="info"
          >Keep track of your readiness status in notifications
        </v-snackbar>
        <v-card :loading="loading.data">
          <v-card-subtitle>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              solo
              single-line
              hide-details
            ></v-text-field>
          </v-card-subtitle>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="items"
            :items-per-page="15"
            class="elevation-1"
            show-select
          >
            <template v-slot:item.good_bad="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ item.good_all }} <sup>{{ item.good }}%</sup>
                  </span>
                </template>
                <span> Left: {{ item.nrd_left }} keys </span>
              </v-tooltip>
            </template>
            <template v-slot:item.ah_sw_pro="{ item }">
              <v-btn
                color="secondary"
                depressed
                icon
                @click="openProgressParamsDialog(item)"
              >
                <v-icon> mdi-progress-clock </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.related="{ item }">
              {{ item.related }}%
            </template>
            <template v-slot:item.suggestion="{ item }">
              {{ item.suggestion }}%
            </template>
            <template v-slot:item.serp="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <span v-if="item.serp != -1"
                      >{{
                        calcPercent(item.serp.c_collected, item.serp.c_all)
                      }}%</span
                    >
                    <span v-else>-1</span>
                  </div>
                </template>
                <span>
                  {{ item.serp.c_collected }} / {{ item.serp.c_all }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div style="white-space: nowrap">
                <v-btn
                  color="secondary"
                  depressed
                  icon
                  @click="openEditDialog(item)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  color="secondary"
                  depressed
                  icon
                  link
                  :to="{
                    name: 'semantic.tool.keyword',
                    params: { id: item.id },
                  }"
                >
                  <v-icon> mdi-key-chain </v-icon>
                </v-btn>
                <v-btn
                  color="secondary"
                  depressed
                  icon
                  link
                  :to="{
                    name: 'semantic.tool.dashboard',
                    params: { id: item.id },
                  }"
                >
                  <v-icon> mdi-login-variant </v-icon>
                </v-btn>
                <v-tooltip bottom v-if="item.export_status">
                  <template v-slot:activator="{ on }">
                    <v-btn color="secondary" v-on="on" depressed icon>
                      <v-icon>mdi-airplane-clock</v-icon>
                    </v-btn>
                  </template>
                  Progress: {{ item.export_progress }} % <br />
                  Export started {{ item.export_created_at }} ago <br />
                  Pos in queue: {{ item.pos_in_queue }} /
                  {{ item.tasks_in_queue }} <br />
                  User Export: {{ item.user_export }}
                </v-tooltip>
                <v-menu offset-y v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      :loading="loading.export"
                      icon
                    >
                      <v-icon>mdi-cloud-download-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="createExportTask(2, { taskID: item.id })"
                    >
                      <v-list-item-title>Serp for KeyAssort</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="createExportTask(3, { taskID: item.id })"
                    >
                      <v-list-item-title>Serp FULL</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openExportProDialog(item)">
                      <v-list-item-title>Pro</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="createExportTask(7, { taskID: item.id })"
                    >
                      <v-list-item-title>Related</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="createExportTask(8, { taskID: item.id })"
                    >
                      <v-list-item-title>Suggest</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="createExportTask(13, { taskID: item.id })"
                    >
                      <v-list-item-title>ALL Keywords</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn icon @click="handleOpenShareDialog(item)">
                  <v-icon>mdi-link-variant</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-data-table
            :headers="progress_headers"
            :items="progress_items"
            :items-per-page="15"
            class="elevation-1"
            show-select
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-dialog
          v-model="params_dialog"
          max-width="800"
          content-class="my-shadow--e4"
          scrollable
          @click:outside="handleCloseParamsDialog"
        >
          <v-card class="styled-card--default">
            <v-card-title>
              Progress Params
              <v-spacer />
              <v-icon @click="handleCloseParamsDialog"> mdi-close </v-icon>
            </v-card-title>
            <v-divider />
            <div class="px-4 py-2">
              <table-search
                :model-value="paramsSearch"
                @update:modelValue="paramsSearch = $event"
                placeholder="Type to search"
                style="max-width: 320px"
              />
            </div>
            <v-divider />
            <v-card-text class="pa-0">
              <v-data-table
                v-model="paramsSelected"
                :search="paramsSearch"
                :headers="params_headers"
                :items="params_items"
                :items-per-page="-1"
                dense
                item-key="domain"
                show-select
              >
                <template #[`item.sw_parsed_at`]="{ value }">
                  <span style="white-space: nowrap">
                    {{ value }}
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                class="reset-btn"
                color="primary"
                @click="handleCollectSwKeys(paramsSelected)"
                :loading="loadings.addDctTask"
              >
                Collect SW Keys
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="pro_export_dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Pro Export</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid_pro">
                <span class="subtitle-1">Our Domain</span>
                <v-text-field
                  outlined
                  label="example.com"
                  v-model="export_data.domain_urls.domain"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <span class="subtitle-1">Volume & GSC Data</span>
                <v-text-field
                  outlined
                  label="Last days #1"
                  v-model="export_data.gsc.dates[0]"
                  type="number"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="Last days #2"
                  v-model="export_data.gsc.dates[1]"
                  type="number"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <span class="subtitle-1">LTT</span>
                <v-text-field
                  outlined
                  label="Last days #1"
                  v-model="export_data.ltt.dates[0]"
                  type="number"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="Last days #2"
                  v-model="export_data.ltt.dates[1]"
                  type="number"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <span class="subtitle-1">Regional & NRD domains</span>
                <v-select
                  outlined
                  label="Need Top"
                  v-model="export_data.regional_nrd_domains.need_top"
                  :rules="[(v) => !!v || 'Item is required']"
                  :items="[1, 3, 5, 10, 20]"
                ></v-select>
                <v-text-field
                  outlined
                  label=".countryZone"
                  v-model="export_data.regional_nrd_domains.country_zone"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-select
                  :items="countries"
                  v-model="export_data.regional_nrd_domains.country"
                  item-text="name"
                  item-value="iso_alpha_2"
                  label="list of countries by SW pro"
                  outlined
                >
                </v-select>
                <span class="subtitle-1">TipTop Analytics</span>
                <v-select
                  outlined
                  label="Need Top"
                  v-model="export_data.tip_top.need_top"
                  :rules="[(v) => !!v || 'Item is required']"
                  :items="[1, 3, 5, 10, 20]"
                ></v-select>
                <span class="subtitle-1">Minimum Values</span>
                <v-select
                  outlined
                  label="Need Top"
                  v-model="export_data.minimum_values.need_top"
                  :rules="[(v) => !!v || 'Item is required']"
                  :items="[1, 3, 5, 10, 20]"
                ></v-select>
                <v-text-field
                  outlined
                  label="Min volume"
                  v-model="export_data.minimum_values.min_volume"
                  type="number"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <span class="subtitle-1">Range of Positions</span>
                <v-text-field
                  outlined
                  label="Position"
                  v-model="export_data.range_of_positions.position"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="GSC Position"
                  v-model="export_data.range_of_positions.gsc_position"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <span class="subtitle-1">Positions Compare</span>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      v-model="export_data.compare_dates"
                      @input="doFormat($event, '***-***-***', '')"
                      label="Compare dates"
                      dense
                      hide-details
                      outlined
                      placeholder="###–###–###"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="export_data.compare_top"
                      type="number"
                      label="Need top"
                      dense
                      :max="20"
                      :min="1"
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <span class="subtitle-1">Range of Params</span>
                <v-select
                  outlined
                  label="Need Top"
                  v-model="export_data.range_of_params.need_top"
                  :rules="[(v) => !!v || 'Item is required']"
                  :items="[1, 3, 5, 10, 20]"
                ></v-select>
                <v-text-field
                  outlined
                  label="Ahrefs DR Range"
                  v-model="export_data.range_of_params.ah_dr"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="Ahrefs RD Range"
                  v-model="export_data.range_of_params.ah_rd"
                  :rules="[(v) => !!v || 'Item is range_of_params']"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="Whois Age Range"
                  v-model="export_data.range_of_params.age"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="WebArchive Age Range"
                  v-model="export_data.range_of_params.wa_age"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="Google Index Range"
                  v-model="export_data.range_of_params.gi"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <span class="subtitle-1">Detailed Links Data</span>
                <v-select
                  outlined
                  label="Need Top"
                  v-model="export_data.info_queries.need_top"
                  :rules="[(v) => !!v || 'Item is required']"
                  :items="[1, 3, 5, 10, 20]"
                ></v-select>
                <v-textarea
                  outlined
                  label="List of phrases/words"
                  no-resize
                  rows="5"
                  v-model="export_data.info_queries.phrases"
                ></v-textarea>
                <span class="subtitle-1">Domains Position</span>
                <v-textarea
                  outlined
                  label="List of domains"
                  no-resize
                  rows="5"
                  v-model="export_data.domains_position.domains"
                ></v-textarea>
                <v-checkbox
                  v-model="export_data.add_to_export_gsc_data"
                  label="Add to Export GSC data"
                  class="ma-0"
                ></v-checkbox>
                <v-checkbox
                  v-model="export_data.add_to_keywords_control"
                  label="Add to Keywords control"
                  class="ma-0"
                ></v-checkbox>
                <v-text-field
                  v-model="export_data.min_gcs_imp"
                  type="number"
                  label="Min GCS Imp"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="export_data.num_of_months"
                  type="number"
                  label="Num of months"
                  outlined
                ></v-text-field>
                <v-autocomplete
                  v-model="export_data.nrd_positions_source"
                  label="NRD Positions Source"
                  :items="[
                    {
                      text: 'Standart',
                      value: 'standart',
                    },
                    {
                      text: 'Current Medium 7 days',
                      value: 'current_medium_7_days',
                    },
                  ]"
                  hide-details
                  outlined
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="pro_export_dialog = false" text> Close </v-btn>
              <v-btn
                color="primary"
                @click="
                  createExportTask(1, { taskID: task_id, data: export_data })
                "
                :loading="loading.export"
              >
                Export
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="short_term.dialog" max-width="1000px">
          <v-card>
            <v-card-title>
              <span class="headline" v-if="edit_dialog">Edit Settings</span>
              <span class="headline" v-else>Create Project</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-stepper alt-labels v-model="e1">
                        <v-stepper-header>
                          <v-stepper-step :complete="e1 > 1" step="1">
                            Search Engine
                          </v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step :complete="e1 > 2" step="2">
                            Base Keywords
                          </v-stepper-step>

                          <v-divider></v-divider>

                          <template v-if="false">
                            <v-stepper-step :complete="e1 > 3" step="3">
                              Parsing By Domains
                            </v-stepper-step>

                            <v-divider></v-divider>
                          </template>

                          <v-stepper-step :complete="e1 > 3" step="3">
                            Parsing Additional Semantic
                          </v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step :complete="e1 > 4" step="4">
                            Niche Related Domains (NRD)
                          </v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step step="5">
                            Connect to SpiderLink.Pro
                          </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                          <v-stepper-content step="1">
                            <v-card elevation="0">
                              <v-card-text>
                                <v-form ref="form" v-model="valid">
                                  <v-text-field
                                    outlined
                                    label="Name"
                                    v-model="task_data.s1.name"
                                    :rules="[(v) => !!v || 'Item is required']"
                                  ></v-text-field>
                                  <v-autocomplete
                                    outlined
                                    :items="filtersData.niches"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    v-model="task_data.s1.niche"
                                    placeholder="Type to search"
                                    label="Niche"
                                  ></v-autocomplete>
                                  <v-autocomplete
                                    outlined
                                    label="Domain Zone"
                                    :items="search_engines"
                                    v-model="task_data.s1.search_engine"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    :disabled="edit_dialog"
                                  ></v-autocomplete>
                                  <v-autocomplete
                                    outlined
                                    :items="LANGUAGES()"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    v-model="task_data.s1.language"
                                    label="Language"
                                    item-text="country"
                                    item-value="iso"
                                    :disabled="edit_dialog"
                                  ></v-autocomplete>
                                  <v-select
                                    outlined
                                    label="Top Depth"
                                    v-model="task_data.s1.top_depth"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    :items="[10, 20]"
                                    :disabled="edit_dialog"
                                  ></v-select>
                                  <v-text-field
                                    outlined
                                    label="Min NRD"
                                    v-model="task_data.s1.min_nrd"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    type="number"
                                    hint="More or equal"
                                    persistent-hint
                                  >
                                    <template v-slot:append-outer>
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-information-outline
                                          </v-icon>
                                        </template>
                                        Min. Niche Related Domains (NRD) in SERP
                                      </v-tooltip>
                                    </template>
                                  </v-text-field>
                                  <v-text-field
                                    v-model="task_data.s1.priority"
                                    outlined
                                    :rules="[
                                      (v) =>
                                        !!v || v !== 0 || 'Item is required',
                                    ]"
                                    label="Priority"
                                    type="number"
                                  ></v-text-field>
                                </v-form>
                              </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="e1++"> Next </v-btn>
                          </v-stepper-content>
                          <v-stepper-content step="2">
                            <v-card elevation="0">
                              <v-card-text>
                                <v-form ref="form" v-model="valid">
                                  <v-row>
                                    <v-col>
                                      <v-textarea
                                        outlined
                                        label="Base keywords with volume, kd"
                                        no-resize
                                        rows="6"
                                        :rules="rules_base_keywords"
                                        v-model="task_data.s2.base_keywords"
                                        required
                                        hint="write my essay;1500;35"
                                        :persistent-hint="true"
                                      ></v-textarea>
                                    </v-col>
                                    <v-col cols="3">
                                      <v-checkbox
                                        v-model="task_data.s2.base_keywords_nrd"
                                        label="Filter By NRD"
                                        hide-details
                                      ></v-checkbox>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-card-text>
                            </v-card>
                            <v-btn @click="e1--" text> Back </v-btn>
                            <v-btn color="primary" @click="e1++"> Next </v-btn>
                          </v-stepper-content>
                          <v-stepper-content v-if="false" step="3">
                            <v-card elevation="0">
                              <v-card-text>
                                <v-form ref="form" v-model="valid">
                                  <v-checkbox
                                    v-model="task_data.s3.sw_active"
                                    label="SimilarWeb Pro Keywords"
                                    class="mt-0"
                                  ></v-checkbox>
                                  <v-select
                                    :disabled="!task_data.s3.sw_active"
                                    outlined
                                    label="Period By"
                                    v-model="task_data.s3.sw_period_by"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    :items="['year', 'each month']"
                                  ></v-select>
                                  <v-checkbox
                                    :disabled="!task_data.s3.sw_active"
                                    v-model="task_data.s3.sw_nrd"
                                    label="Filter By NRD"
                                    class="mt-0"
                                  ></v-checkbox>
                                  <v-textarea
                                    :disabled="!task_data.s3.sw_active"
                                    outlined
                                    label="List of domains"
                                    no-resize
                                    rows="6"
                                    v-model="
                                      task_data.s3.sw_parsing_list_domains
                                    "
                                    required
                                  ></v-textarea>
                                  <v-checkbox
                                    v-model="task_data.s3.ah_active"
                                    label="Ahrefs Keywords"
                                    class="mt-0"
                                  ></v-checkbox>
                                  <v-autocomplete
                                    :disabled="!task_data.s3.ah_active"
                                    outlined
                                    label="Domain Zone"
                                    :items="search_engines"
                                    v-model="task_data.s3.ah_domain_zone"
                                    :rules="[(v) => !!v || 'Item is required']"
                                  ></v-autocomplete>
                                  <v-select
                                    :disabled="!task_data.s3.ah_active"
                                    outlined
                                    label="Max Top"
                                    v-model="task_data.s3.ah_max_top"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    :items="[10, 20]"
                                  ></v-select>
                                  <v-checkbox
                                    :disabled="!task_data.s3.ah_active"
                                    v-model="task_data.s3.ah_nrd"
                                    label="Filter By NRD"
                                    class="mt-0"
                                  ></v-checkbox>
                                  <v-textarea
                                    :disabled="!task_data.s3.ah_active"
                                    outlined
                                    label="List of domains"
                                    no-resize
                                    rows="6"
                                    v-model="
                                      task_data.s3.ah_parsing_list_domains
                                    "
                                    required
                                    hint="Max 30 domains"
                                  ></v-textarea>
                                </v-form>
                              </v-card-text>
                            </v-card>
                            <v-btn @click="e1--" text> Back </v-btn>
                            <v-btn color="primary" @click="e1++"> Next </v-btn>
                          </v-stepper-content>
                          <v-stepper-content step="3">
                            <v-card elevation="0">
                              <v-card-text>
                                <v-form ref="form" v-model="valid">
                                  <v-checkbox
                                    v-model="task_data.s4.suggestion_active"
                                    label="Suggestion"
                                    class="mt-0"
                                    :disabled="edit_dialog"
                                  ></v-checkbox>
                                  <v-select
                                    :disabled="
                                      !task_data.s4.suggestion_active ||
                                      edit_dialog
                                    "
                                    outlined
                                    label="To level"
                                    v-model="task_data.s4.suggestion_level"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    :items="[1, 2, 3]"
                                  ></v-select>
                                  <v-checkbox
                                    v-model="task_data.s4.related_active"
                                    label="Related"
                                    class="mt-0"
                                    :disabled="edit_dialog"
                                  ></v-checkbox>
                                  <v-select
                                    :disabled="
                                      !task_data.s4.related_active ||
                                      edit_dialog
                                    "
                                    outlined
                                    label="To level"
                                    v-model="task_data.s4.related_level"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    :items="[1, 2, 3]"
                                  ></v-select>
                                  <v-checkbox
                                    v-model="task_data.s4.google_trends_active"
                                    label="Google Trends"
                                    class="mt-0"
                                    :disabled="edit_dialog"
                                  ></v-checkbox>
                                  <v-select
                                    :disabled="
                                      !task_data.s4.google_trends_active ||
                                      edit_dialog
                                    "
                                    outlined
                                    label="To level"
                                    v-model="task_data.s4.google_trends_level"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    :items="[1, 2, 3]"
                                  ></v-select>
                                  <v-checkbox
                                    :disabled="
                                      !task_data.s4.related_active ||
                                      edit_dialog
                                    "
                                    v-model="task_data.s4.related_nrd"
                                    label="Filter By NRD"
                                    class="mt-0"
                                  ></v-checkbox>
                                </v-form>
                              </v-card-text>
                            </v-card>
                            <v-btn @click="e1--" text> Back </v-btn>
                            <v-btn color="primary" @click="e1++"> Next </v-btn>
                          </v-stepper-content>
                          <v-stepper-content step="4">
                            <v-card elevation="0">
                              <v-card-text>
                                <v-form ref="form" v-model="valid">
                                  <v-textarea
                                    outlined
                                    label="List of domains"
                                    no-resize
                                    rows="6"
                                    v-model="task_data.s5.list_of_domains"
                                    required
                                  ></v-textarea>

                                  <v-textarea
                                    outlined
                                    label="List of sub folders"
                                    no-resize
                                    rows="6"
                                    v-model="task_data.s5.list_of_sub_folders"
                                    required
                                  ></v-textarea>
                                </v-form>
                              </v-card-text>
                            </v-card>
                            <v-btn @click="e1--" text> Back </v-btn>
                            <v-btn color="primary" @click="e1++"> Next </v-btn>
                          </v-stepper-content>
                          <v-stepper-content step="5">
                            <v-card elevation="0">
                              <v-card-text>
                                <v-form ref="form" v-model="valid">
                                  <v-text-field
                                    outlined
                                    label="Task ID"
                                    no-resize
                                    rows="6"
                                    v-model="task_data.s6.sl_task_id"
                                    required
                                  ></v-text-field>
                                </v-form>
                              </v-card-text>
                            </v-card>
                            <v-btn @click="e1--" text> Back </v-btn>
                            <v-btn
                              color="primary"
                              @click="updateTask"
                              :loading="loading.create"
                              v-if="edit_dialog"
                            >
                              Update
                            </v-btn>
                            <v-btn
                              color="primary"
                              @click="createTask"
                              :loading="loading.create"
                              v-else
                            >
                              Create
                            </v-btn>
                          </v-stepper-content>
                        </v-stepper-items>
                      </v-stepper>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-dialog
      v-model="shareDialogModel"
      content-class="remove-dialog-shadow"
      max-width="350px"
    >
      <v-card class="shadow-e2-bordered" rounded="lg">
        <v-card-title>Share with</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="shareItem.email"
            dense
            outlined
            label="Email"
            placeholder="Enter email"
            :rules="emailRules"
            hide-details="auto"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="handleShare" color="primary">Share</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import eventPipe from "@/events/eventPipe";
import service from "@/plugins/service";
import ExportTaskService from "@/services/ExportTaskService";
import DctTaskService from "@/services/dct-task";
import TableSearch from "@/components/UI/TableSearch.vue";
import { LANGUAGES } from "../../utils/defaultData";
import UploadFiltersDataMixin from "../../mixins/UploadFiltersDataMixin";

export default {
  name: "SemanticToolIndex",
  components: { TableSearch },
  mixins: [UploadFiltersDataMixin],
  data() {
    return {
      filtersData: {
        niches: [],
      },
      shareDialogModel: false,
      shareItem: {
        id: null,
        email: null,
      },
      snackbar: false,
      snackbar_export_pro: false,
      selected: [],
      search: "",
      valid_pro: false,
      headers: [
        { text: "ID", value: "id", width: 50 },
        { text: "Name", value: "name" },
        { text: "Priority", value: "priority" },
        { text: "Niche", value: "niche" },
        { text: "Country Lang", value: "country_lang" },
        { text: "Keywords", value: "keywords" },
        { text: "Good", value: "good_bad" },
        { text: "NRD", value: "nrd" },
        { text: "AH | SW PRO", value: "ah_sw_pro", align: "center" },
        { text: "Suggestion", value: "suggestion" },
        { text: "Related", value: "related" },
        { text: "Serp", value: "serp" },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          width: 180,
        },
      ],
      progress_headers: [
        { text: "title", value: "title" },
        { text: "1h", value: "1h" },
        { text: "6h", value: "6h" },
        { text: "in queue", value: "in_queue" },
      ],
      progress_items: [],
      search_engines: [
        "com",
        "ac",
        "ad",
        "ae",
        "com.af",
        "com.ag",
        "com.ai",
        "al",
        "am",
        "co.ao",
        "com.ar",
        "as",
        "at",
        "com.au",
        "az",
        "ba",
        "com.bd",
        "be",
        "bf",
        "bg",
        "com.bh",
        "bi",
        "bj",
        "com.bn",
        "com.bo",
        "com.br",
        "bs",
        "co.bw",
        "by",
        "com.bz",
        "ca",
        "com.kh",
        "cc",
        "cd",
        "cf",
        "cat",
        "cg",
        "ch",
        "ci",
        "co.ck",
        "cl",
        "cm",
        "cn",
        "cn",
        "com.co",
        "co.cr",
        "com.cu",
        "cv",
        "com.cy",
        "cz",
        "de",
        "dj",
        "dk",
        "dm",
        "com.do",
        "dz",
        "com.ec",
        "ee",
        "com.eg",
        "es",
        "com.et",
        "fi",
        "com.fj",
        "fm",
        "fr",
        "ga",
        "ge",
        "gf",
        "gg",
        "com.gh",
        "com.gi",
        "gl",
        "gm",
        "gp",
        "gr",
        "com.gt",
        "gy",
        "com.hk",
        "hn",
        "hr",
        "ht",
        "hu",
        "co.id",
        "iq",
        "ie",
        "co.il",
        "im",
        "co.in",
        "io",
        "is",
        "it",
        "je",
        "com.jm",
        "jo",
        "co.jp",
        "co.ke",
        "com.kh",
        "ki",
        "kg",
        "co.kr",
        "com.kw",
        "kz",
        "la",
        "com.lb",
        "com.lc",
        "li",
        "lk",
        "co.ls",
        "lt",
        "lu",
        "lv",
        "com.ly",
        "co.ma",
        "md",
        "me",
        "mg",
        "mk",
        "ml",
        "mn",
        "ms",
        "com.mt",
        "mu",
        "mv",
        "mw",
        "com.mx",
        "com.my",
        "co.mz",
        "com.na",
        "ne",
        "com.nf",
        "com.ng",
        "com.ni",
        "nl",
        "no",
        "com.np",
        "nr",
        "nu",
        "co.nz",
        "com.om",
        "com.pa",
        "com.pe",
        "com.ph",
        "com.pk",
        "pl",
        "com.pg",
        "pn",
        "com.pr",
        "ps",
        "pt",
        "com.py",
        "com.qa",
        "ro",
        "rs",
        "ru",
        "rw",
        "com.sa",
        "com.sb",
        "sc",
        "se",
        "com.sg",
        "sh",
        "si",
        "sk",
        "com.sl",
        "sn",
        "sm",
        "so",
        "st",
        "com.sv",
        "td",
        "tg",
        "co.th",
        "com.tj",
        "tk",
        "tl",
        "tm",
        "to",
        "com.tn",
        "com.tr",
        "tt",
        "com.tw",
        "co.tz",
        "com.ua",
        "co.ug",
        "co.uk",
        "com.uy",
        "co.uz",
        "com.vc",
        "co.ve",
        "vg",
        "co.vi",
        "com.vn",
        "vu",
        "ws",
        "co.za",
        "co.zm",
        "co.zw",
        "com.mm",
      ],
      project_active: {},
      items: [],
      valid: true,
      edit_dialog: false,
      loading: {
        countries: false,
        languages: false,
        active: false,
        data: false,
        create: false,
        export: false,
      },
      short_term: {
        dialog: false,
        name: undefined,
        country: undefined,
        language: undefined,
        niche: undefined,
        description: undefined,
      },
      pro_export_dialog: false,
      export_data: {
        nrd_positions_source: null,
        add_to_export_gsc_data: false,
        add_to_keywords_control: false,
        min_gcs_imp: 10,
        num_of_months: 12,
        info_queries: {
          need_top: 10,
          phrases: "",
        },
        domain_urls: {
          domain: "example.com",
        },
        gsc: {
          dates: [90, 365],
        },
        ltt: {
          dates: [60, 180],
        },
        regional_nrd_domains: {
          need_top: 10,
          country_zone: ".com",
          country: "US",
        },
        tip_top: {
          need_top: 10,
        },
        minimum_values: {
          need_top: 10,
          min_volume: -1,
        },
        range_of_positions: {
          position: "1-30-60-90-180-365-540",
          gsc_position: "3-30-60-90-180-365-540",
        },
        range_of_params: {
          need_top: 10,
          ah_dr: "20-30-40-50-60",
          ah_rd: "50-100-300-500-1000",
          age: "1-2-3",
          wa_age: "1-2-3",
          gi: "50-200-500-1000",
        },
        domains_position: {
          domains: "",
        },
        compare_dates: "150-270-540",
        compare_top: null,
      },
      e1: 1,
      task_id: null,
      task_data: {
        s1: {
          priority: 0,
          name: "Default name",
          top_depth: 20,
          min_nrd: 2,
          search_engine: "com",
          language: "en",
          niche: 1,
        },
        s2: {
          base_keywords: "write my essay;1500\nwrite my essay for me;1000",
          base_keywords_nrd: true,
          translated_base_keywords: "",
          translated_base_keywords_nrd: true,
          translated_base_keywords_language_from: null,
          translated_base_keywords_language_to: null,
        },
        s3: {
          ah_active: false,
          sw_active: true,
          ah_domain_zone: "com",
          ah_max_top: 20,
          ah_nrd: false,
          sw_period_by: "year",
          sw_nrd: true,
          ah_parsing_list_domains: "paperell.com",
          sw_parsing_list_domains: "paperell.com",
        },
        s4: {
          suggestion_active: true,
          suggestion_level: 2,
          related_active: true,
          related_level: 2,
          google_trends_active: true,
          google_trends_level: 2,
          related_nrd: true,
        },
        s5: {
          list_of_domains: "paperell.com",
          list_of_sub_folders: "paperell.com/us/",
        },
        s6: {
          sl_task_id: 0,
        },
      },
      rules_base_keywords: [
        (v) => !!v || "Base keywords is required",
        (v) =>
          (v !== undefined && v.trim().split("\n").length <= 25000) ||
          "Base keywords should be less than 25000",
      ],
      paramsSearch: null,
      loadings: {
        addDctTask: false,
      },
      paramsSelected: [],
      params_dialog: false,
      params_headers: [
        { text: "Domain", value: "domain" },
        { text: "SW", value: "sw" },
        { text: "AH", value: "ah" },
        { text: "SW Parsed At", value: "sw_parsed_at" },
        { text: "AH Parsed At", value: "ah_parsed_at" },
      ],
      params_items: [],
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  computed: {
    countries() {
      return this.$store.state.reuse.countries;
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
    downloadLink() {
      return (
        this.$store.state.server_url_clear +
        "/api/semantic-tool/export-pro-download/" +
        this.task_id
      );
    },
  },
  methods: {
    LANGUAGES() {
      return LANGUAGES;
    },
    handleCloseParamsDialog(e) {
      e?.preventDefault();

      this.params_dialog = false;
      this.paramsSelected = [];
    },
    async handleCollectSwKeys(domains) {
      const payload = {
        name: "ST SW Keys",
        domains: domains.map((v) => v.domain),
        params: [11],
        country: 1,
        niche: 2,
        region: [],
        ignore: false,
        onlyHistoricalData: 0,
        service: "ST",
      };

      this.loadings.addDctTask = true;

      await DctTaskService.addTask(this, payload);

      this.loadings.addDctTask = false;
      this.paramsSelected = [];
    },
    doFormat(value, pattern, mask) {
      const strippedValue = value.replace(/[^0-9]/g, "");
      const chars = strippedValue.split("");
      let count = 0;

      let formatted = "";
      for (let i = 0; i < pattern.length; i++) {
        const c = pattern[i];
        if (chars[count]) {
          if (/\*/.test(c)) {
            formatted += chars[count];
            count++;
          } else {
            formatted += c;
          }
        } else if (mask) {
          if (mask.split("")[i]) formatted += mask.split("")[i];
        }
      }

      return (this.export_data.compare_dates = formatted);
    },
    async handleShare() {
      const url = `/semantic-tool/task/share`;

      await service.post(url, this.shareItem);

      this.shareItem = { id: null, email: null };

      this.shareDialogModel = false;
    },
    handleOpenShareDialog(item) {
      this.shareItem.id = item.id;
      this.shareDialogModel = true;
    },
    openProgressParamsDialog(item) {
      let self = this;

      let url = `${self.$store.state.server_url}/semantic-tool/task/progress-params`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
        params: {
          taskID: item.id,
        },
      };

      axios.get(url, config).then((r) => {
        self.params_items = r.data.items;
        self.params_dialog = true;
      });
    },
    async saveUserSettings() {
      try {
        const url = "/user/save-settings";
        const payload = {
          type: "st_export_pro_" + this.task_id,
          data: this.export_data,
        };

        await service.post(url, payload);
      } catch (e) {
        console.error("Error while saving user settings.", e);
      }
    },
    async createExportTask(typeID, json) {
      const exportService = new ExportTaskService({
        type: typeID,
        json,
        context: this,
      });

      this.loading.export = true;

      await exportService.createExport();

      this.loading.export = false;
      this.pro_export_dialog = false;
      this.snackbar_export_pro = true;
      this.loading.export = false;

      this.saveUserSettings();
      this.fetchData();
    },
    async exportSerp(item) {
      const exportService = new ExportTaskService({
        type: 2,
        json: { taskID: item.id },
        context: this,
      });
      this.loading.export = true;
      await exportService.createExport();

      this.snackbar_export_pro = true;
      this.loading.export = false;
    },
    exportPro() {
      let self = this;

      self.loading.export = true;

      let url = `${self.$store.state.server_url}/semantic-tool/task/export-pro`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
        params: {
          taskID: self.task_id,
        },
      };

      axios
        .post(url, self.export_data, config)
        .then(() => {
          self.pro_export_dialog = false;
          self.snackbar_export_pro = true;
          self.fetchData();
        })
        .finally(() => {
          self.loading.export = false;
        })
        .catch((e) => alert(e));
    },
    openExportProDialog(item) {
      let self = this;

      let url = `${self.$store.state.server_url}/user/get-settings`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
        params: {
          types: ["st_export_pro_" + item.id],
        },
      };

      axios.get(url, config).then((r) => {
        if (
          r.data["st_export_pro_" + item.id] !== undefined &&
          r.data["st_export_pro_" + item.id] !== null
        ) {
          self.export_data = r.data["st_export_pro_" + item.id];
        }
        self.task_id = item.id;
        self.pro_export_dialog = true;
      });
    },
    openEditDialog(item) {
      let self = this;

      self.task_id = item.id;
      self.task_data = item.json;
      self.task_data.s1.priority = item.priority;
      self.task_data.s2.base_keywords = "";

      self.short_term.dialog = true;
      self.edit_dialog = true;
    },
    calcPercent(a, b) {
      return Math.round((a * 100) / b);
    },
    async createTask() {
      try {
        this.loading.create = true;

        const url = "/semantic-tool/task/create";

        await service.post(url, this.task_data);
      } catch (e) {
        console.error("Error while creating task.", e);
      } finally {
        this.fetchData();
        this.loading.create = false;
        this.short_term.dialog = false;
      }
    },
    updateTask() {
      let self = this;

      self.loading.create = true;

      let url = `${self.$store.state.server_url}/semantic-tool/task/update`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
        params: {
          taskID: self.task_id,
        },
      };

      axios
        .post(url, self.task_data, config)
        .then(() => {
          self.short_term.dialog = false;
        })
        .finally(() => {
          self.fetchData();
          self.loading.create = false;
        })
        .catch((e) => alert(e));
    },
    selectProject(id) {
      let self = this;

      self.$store.dispatch("update_active_na_project", id);

      eventPipe.$emit("updateMenu", {
        child_hidden: [
          {
            link: "home",
            icon: "mdi-chevron-left",
            title: "Main Dashboard",
            base: true,
          },
          {
            link: "niche.analyzer.index",
            icon: "mdi-television-classic",
            title: "All Projects",
          },
          {
            link: "niche.analyzer.project-dashboard",
            icon: "mdi-television-classic",
            title: "Project Dashboard",
          },
          {
            link: "niche.analyzer.detailed-static",
            icon: "mdi-chart-timeline-variant",
            title: "Detailed Static",
          },
        ],
      });

      self.$router.push({ name: "niche.analyzer.project-dashboard" });
    },
    shortTermCreate() {
      console.log(this.task_data);
    },
    fetchData() {
      let self = this;

      self.loading.data = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(`${self.$store.state.server_url}/semantic-tool/task/all`, config)
        .then((r) => {
          self.items = r.data.items;
        })
        .finally(() => (self.loading.data = false))
        .catch((e) => alert(e));
    },
    fetchProgress() {
      let self = this;

      self.loading.data = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(
          `${self.$store.state.server_url}/semantic-tool/task/progress`,
          config
        )
        .then((r) => {
          self.progress_items = r.data.items;
        })
        .finally(() => (self.loading.data = false))
        .catch((e) => alert(e));
    },
  },
  mounted() {
    this.$store.dispatch("fetch_countries_list");
  },
  created() {
    this.fetchData();
    this.fetchProgress();
    this.uploadFiltersData(["niches"]);

    if (this.niches.length === 0) {
      this.$store.dispatch("fetch_niches_list");
    }
  },
};
</script>

<style scoped></style>
