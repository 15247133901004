<template>
  <div class="site-overview">
    <v-row dense>
      <v-col cols="12">
        <page-name small=""> 4DX Site Overview </page-name>
      </v-col>
      <v-col cols="12">
        <div class="custom-bordered-wrapper">
          <v-row dense>
            <v-col>
              <v-autocomplete
                hide-details
                dense
                outlined
                label="SEO"
                placeholder="Type to search"
                v-model="seo"
                :items="filtersData.seo || []"
                :loading="loadings.filters"
                @change="_$handleFilterChange($event, 'seo')"
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <range-filter
                title="Need Top"
                :model-value="need_top"
                @update:modelValue="_$handleRangeChange($event, 'need_top')"
                :presets="needTopPresets"
                :max="255"
              />
            </v-col>
            <v-col>
              <date-filter
                :model-value="date"
                @update:modelValue="handleDateChange"
              />
            </v-col>
            <v-col>
              <div class="fix-button-height">
                <v-btn
                  block
                  @click="handleGetData"
                  color="primary"
                  :loading="loadings.table"
                >
                  Get Data
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12">
        <table-wrapper
          :loading="loadings.table"
          :items="tableData.items"
          :first-enter="firstEnter"
        >
          <template #default>
            <v-data-table
              :headers="tableData.headers"
              :items="tableData.items"
              :items-per-page="-1"
              hide-default-footer
            >
              <template #body="{ items }">
                <tbody>
                  <tr v-for="(row, index) in items" :key="index">
                    <td
                      class="text-start clicked"
                      style="cursor: pointer"
                      @click="goToUrlOverview(row.id)"
                    >
                      {{ row.name }}
                    </td>
                    <uo-td-status
                      :value="row.status"
                      @push="goToUrlOverview(row.id)"
                    />
                    <uo-td-l-sc :value="row.l_sc" :data="row" :seo="seo" />
                    <uo-td-sc-sc
                      :value="row.sc_sc"
                      :data="row"
                      :needTop="need_top"
                    />
                    <uo-td-sw-sc
                      :value="row.sw_sc"
                      :data="row"
                      :needTop="need_top"
                    />
                    <uo-td-t-sc :value="row.t_sc" :data="row" :seo="seo" />
                    <uo-td-nt-percent :value="row.nt_percent" />
                    <uo-td-delta :data="row['1']" />
                    <uo-td-delta :data="row['3']" />
                    <uo-td-delta :data="row['10']" />
                    <uo-td-delta :data="row['20']" />
                    <td class="green-td">{{ row.r }}</td>
                    <td class="red-td">{{ row.f }}</td>
                    <uo-td-trends-imp :data="row.trends_imp" />
                    <uo-td-trends-cl :data="row.trends_cl" />
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </template>
        </table-wrapper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TableWrapper from "@/components/UI/TableWrapper";
import PageName from "@/components/DashboardDev/PageName";
import RangeFilter from "@/components/PositionsDashboard/Header/RangeFilter";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import DateFilter from "@/components/PositionsDashboard/Header/DateFilter";
import { DEFAULT_HEADERS_SITE_OVERVIEW_TABLE } from "@/utils/defaultData";
import UoTdDelta from "@/components/Dashboards/UrlsOverview/Td/UoTdDelta";
import UoTdTrendsCl from "@/components/Dashboards/UrlsOverview/Td/UoTdTrendsCl";
import UoTdTrendsImp from "@/components/Dashboards/UrlsOverview/Td/UoTdTrendsImp";
import UoTdNtPercent from "@/components/Dashboards/UrlsOverview/Td/UoTdNtPercent";
import UoTdTSc from "@/components/Dashboards/UrlsOverview/Td/UoTdTSc";
import UoTdSwSc from "@/components/Dashboards/UrlsOverview/Td/UoTdSwSc";
import UoTdScSc from "@/components/Dashboards/UrlsOverview/Td/UoTdScSc";
import UoTdLSc from "@/components/Dashboards/UrlsOverview/Td/UoTdLSc";
import UoTdStatus from "@/components/Dashboards/UrlsOverview/Td/UoTdStatus";
import Location from "../../../mixins/Location";

export default {
  components: {
    DateFilter,
    RangeFilter,
    PageName,
    TableWrapper,
    UoTdDelta,
    UoTdTrendsCl,
    UoTdTrendsImp,
    UoTdNtPercent,
    UoTdTSc,
    UoTdSwSc,
    UoTdScSc,
    UoTdLSc,
    UoTdStatus,
  },
  mixins: [FiltersHandling, Location],
  data: () => ({
    loadings: {
      table: false,
      filters: false,
    },
    needTopPresets: [
      ["1-3", [1, 3]],
      ["1-5", [1, 5]],
      ["4-10", [4, 10]],
      ["4-20", [4, 20]],
      ["1-50", [1, 50]],
      ["11-50", [11, 50]],
    ],
    firstEnter: true,
    tableData: {
      headers: DEFAULT_HEADERS_SITE_OVERVIEW_TABLE,
      items: [],
    },
    // filters
    need_top: [0, 0],
    date: [],
    seo: null,
    // end filters
    filtersData: {},
  }),
  created() {
    this._$collectParams(["date", "need_top"]);
    this.fetchFilters();

    const haveDate = this.$route.query.date;
    if (!haveDate) {
      this.date = this.getDefaultDate;
    }

    if (JSON.stringify(this.need_top) === "[0,0]") {
      this._$handleRangeChange([1, 50], "need_top");
    }

    this.fetchData();
  },
  computed: {
    getFilters() {
      const { date, seo, need_top } = this;
      const output = {};

      if (date.length !== 0) {
        output.date = date;
      }

      output.seo = seo;

      if (JSON.stringify(need_top) !== "[0,0]") {
        output.need_top = need_top;
      }

      return output;
    },
    getDefaultDate() {
      const date = new Date();
      const start = this.$moment(date)
        .subtract(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(date).format("YYYY-MM-DD");
      return [start, end];
    },
  },
  methods: {
    goToUrlOverview(projectId) {
      const url = "/dashboards/urls-overview";
      const bannedParams = ["seo"];
      const params = {
        ...this.getFilters,
        project: projectId,
      };
      this.routerPushWithQueries(params, url, bannedParams);
    },
    routerPushWithQueries(params, url, bannedKeys = []) {
      const query = {};
      let queryString = "";

      Object.keys(params).forEach((key) => {
        if (bannedKeys.includes(key)) return;
        query[key] = params[key];
      });

      Object.keys(query).forEach((key, index) => {
        const prefix = index === 0 ? "?" : "&";
        queryString += `${prefix}${key}=${query[key]}`;
      });

      const path = url + queryString;

      window.open(path, "_blank");
    },
    handleDateChange(event) {
      this.date = event;
      this._$handleFilterChange(event, "date");
    },
    handleGetData() {
      this.fetchData();
    },
    async fetchFilters() {
      this.loadings.filters = true;
      this.firstEnter = false;

      const payload = {
        type: "/dashboards/summary/site-overview",
        take: ["seo"],
      };
      const filters = await this.$store.dispatch("global/getFilters", payload);

      this.filtersData.seo = filters.seo;

      this.loadings.filters = false;
    },
    async fetchData() {
      this.loadings.table = true;

      const payload = {
        filter: {
          seo: this.seo,
          date_from: this.date[0],
          date_to: this.date[1],
          need_top: this.need_top,
        },
      };

      const resp = await this.$store.dispatch(
        "site-overview/fetchData",
        payload
      );

      if (resp) {
        this.tableData.items = resp.items;
      }

      this.loadings.table = false;
    },
  },
};
</script>
