export default {
  data: () => ({
    CLASSES: {
      PARENT: "lazy-dom-element",
      HIDDEN: "lazy-dom-element--hidden",
      VISIBLE: "lazy-dom-element--visible",
    },
  }),
  methods: {
    initializeLazyRender(elements) {
      const observerOptions = {
        root: null,
        rootMargin: "0px",
      };

      const observerCallback = (entries) => {
        entries.forEach((entry) => {
          const target = entry.target;
          if (entry.isIntersecting) {
            this.show(target);
          } else {
            this.hide(target);
          }
        });
      };

      const observer = new IntersectionObserver(
        observerCallback,
        observerOptions
      );

      elements.forEach((element) => {
        element.classList.add(this.CLASSES.PARENT);
        element.style.setProperty(
          "--initial-height",
          element.clientHeight + "px"
        );
        element.style.setProperty(
          "--initial-width",
          element.clientWidth + "px"
        );

        this.hide(element);

        observer.observe(element);
      });
    },
    show(element) {
      element.classList.remove(this.CLASSES.HIDDEN);
      element.classList.add(this.CLASSES.VISIBLE);
    },
    hide(element) {
      element.classList.add(this.CLASSES.HIDDEN);
      element.classList.remove(this.CLASSES.VISIBLE);
    },
  },
};
