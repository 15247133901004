<template>
  <v-container
    fluid
    class="task-priority-dashboard"
    style="padding-bottom: 200px"
  >
    <v-row>
      <v-col cols="12">
        <h2>Task Priority</h2>
      </v-col>
    </v-row>
    <v-row dense class="d-flex align-center">
      <v-col>
        <v-autocomplete
          v-model="filters.executive"
          hide-details
          dense
          outlined
          clearable
          chips
          deletable-chips
          small-chips
          multiple
          label="Executive"
          :items="filtersData.executive"
          :loading="loadings.filters"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.labels"
          hide-details
          dense
          outlined
          label="Labels"
          clearable
          multiple
          chips
          deletable-chips
          small-chips
          :items="filtersData.labels"
          :loading="loadings.filters"
        ></v-autocomplete>
      </v-col>
      <v-col></v-col>
      <v-col class="d-flex justify-end">
        <v-switch
          v-model="filters.fresh_data"
          label="Fresh data"
          hide-details
          :true-value="1"
          :false-value="null"
          dense
          class="pa-0 ma-0"
        />
      </v-col>
      <v-col>
        <v-btn
          style="height: 40px"
          color="primary"
          block
          :loading="loadings.table"
          @click="fetchData"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-card rounded="lg" class="shadow-e1-bordered mt-6">
      <v-card-title>
        <v-row dense>
          <v-col cols="12" class="d-flex">
            <v-text-field
              v-model="tableSearch"
              hide-details
              dense
              solo-inverted
              label="Search"
              append-icon="mdi-magnify"
              style="max-width: 330px"
            ></v-text-field>
            <v-autocomplete
              v-model="bannedLabels"
              :items="filtersData.labels"
              hide-details
              dense
              outlined
              multiple
              small-chips
              deletable-chips
              class="ml-2"
              label="Hide labels"
              placeholder="Select"
              style="max-width: 280px"
              @change="_$handleFilterChange($event, 'bannedLabels')"
            ></v-autocomplete>
            <v-spacer />
            <v-menu
              bottom
              min-width="280px"
              content-class="shadow-e1-bordered"
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                <v-btn v-on="on">
                  Settings <v-icon right>mdi-cog-outline</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item>
                  <v-list-item-icon>
                    <v-switch
                      v-model="isHighlightZero"
                      dense
                      class="ma-0 pa-0"
                      hide-details
                    ></v-switch>
                  </v-list-item-icon>
                  <v-list-item-content> Highlight zero </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-data-table
              :search="tableSearch"
              :items="getFilteredItems"
              :headers="getHeaders"
              multi-sort
              :custom-sort="_$smartCustomTableSort"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :loading="loadings.table"
              :options.sync="options"
              :items-per-page="-1"
              class="relative-tr"
              hide-default-footer
            >
              <template
                v-for="h in getHeaders"
                v-slot:[`header.${h.value}`]="{ header }"
              >
                <span :title="header.title" :key="h.value">{{
                  header.text
                }}</span>
              </template>
              <template #[`item.labels`]="{ item }">
                <template
                  v-if="
                    item.labels_data && getLabels(item.labels_data).length > 0
                  "
                >
                  <div class="overflow-chip py-2">
                    <v-chip
                      v-for="[id, label] in getLabels(item.labels_data)"
                      small
                      :key="id"
                    >
                      {{ label }}
                    </v-chip>
                  </div>
                </template>
              </template>
              <template #[`item.week`]="{ value }">
                <div
                  :class="`colored-td colored-td--inline ${getWeekColor(
                    value
                  )}`"
                >
                  {{ value }}
                </div>
              </template>
              <template #[`item.task`]="{ item }">
                <a :href="item.link" target="_blank">
                  {{ item.task }}
                </a>
              </template>
              <template #[`item.project`]="{ item }">
                <div
                  :class="`mark ${
                    item.labels_data &&
                    Object.values(item.labels_data).includes(NEED_REVIEW_TAG)
                      ? 'mark--gray'
                      : ''
                  }`"
                />
                {{ item.project }}
              </template>
              <template
                v-for="col in [
                  'priority',
                  'rating',
                  'impact',
                  'urgency',
                  'peoples',
                  'estimate',
                  'created_at',
                  'deadline',
                ]"
                #[`item.${col}`]="{ item, items, value }"
              >
                <div
                  :style="`background-color:${bggc_getBgColor(
                    value,
                    col,
                    tableData.items,
                    isHighlightZero
                  )};width: 100%; height:100%;left:0;top:0;`"
                  class="px-4 text-center d-flex justify-center align-center"
                  :key="col"
                >
                  {{
                    String(value).includes(".")
                      ? Number(value).toFixed(2)
                      : value
                  }}
                </div>
              </template>
              <template #[`item._actions`]="{ item }">
                <v-menu bottom content-class="shadow-e2-bordered" rounded="lg">
                  <template #activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-progress-circular
                        v-if="loadings.sendToReview.includes(item.id)"
                        indeterminate
                        size="22"
                        width="3"
                      />
                      <v-icon v-else>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="sendToReview(item)">
                      Send to review
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { TASK_PRIORITY_TABLE_HEADERS } from "@/utils/defaultData";
import service from "@/plugins/service";
import TableSorting from "@/mixins/TableSorting";
import getTableColBgGradientColorMixin from "@/mixins/getTableColBgGradientColorMixin";
import { uid } from "@/utils/functions";
import FiltersHandling from "../../mixins/components/FiltersHandling";

export default {
  mixins: [FiltersHandling, TableSorting, getTableColBgGradientColorMixin],
  data: () => ({
    NEED_REVIEW_TAG: "⇵ need review",
    isHighlightZero: false,
    tableSearch: null,
    sortBy: ["week", "rating", "priority", "deadline"],
    sortDesc: [false, true, true, false],
    bannedLabels: [],
    options: {},
    tableData: {
      items: [],
      headers: TASK_PRIORITY_TABLE_HEADERS,
    },
    loadings: {
      table: false,
      filters: false,
      sendToReview: [],
    },
    filters: {
      executive: [],
      labels: [],
      fresh_data: null,
    },
    filtersData: {
      executive: [],
      labels: [],
    },
  }),
  watch: {
    filters: {
      deep: true,
      handler(newValue) {
        this._$handleFiltersChange(newValue);
      },
    },
    options: {
      deep: true,
      handler(newValue) {
        this._$handleFilterChange(newValue.sortBy, "sortBy");
        this._$handleFilterChange(newValue.sortDesc, "sortDesc");
      },
    },
  },
  created() {
    this.bannedLabels = this.$route.query.bannedLabels
      ? this.$route.query.bannedLabels.split(",")
      : [];

    const hasParams = this._$collectParamsTo(this, "filters", [
      "executive",
      "labels",
    ]);

    if (this.$route.query.sortBy) {
      this.options.sortBy = this.$route.query.sortBy.split(",");
    }

    if (this.$route.query.sortDesc) {
      this.options.sortDesc = this.$route.query.sortDesc.split(",");
    }

    this.fetchFilters();

    if (hasParams) this.fetchData();
  },
  computed: {
    getFilteredItems() {
      return this.tableData.items.filter((v) => {
        if (
          Object.values(v.labels_data).find((i) =>
            this.bannedLabels.includes(i)
          )
        ) {
          return false;
        }
        return true;
      });
    },
    getHeaders() {
      return [...this.tableData.headers, { text: "", value: "_actions" }];
    },
  },
  methods: {
    getWeekColor(weekString) {
      if (weekString === "A") return "red";
      if (weekString === "B") return "yellow";
      if (weekString === "C") return "green";

      return "";
    },
    async sendToReview(item) {
      try {
        const url = "/worksection/task-priority/create_task";

        const payload = {
          item: item,
        };

        this.loadings.sendToReview.push(item.id);

        const resp = await service.post(url, payload);

        this.loadings.sendToReview = this.loadings.sendToReview.filter(
          (v) => v !== item.id
        );

        if (resp && resp.data && resp.data.status) {
          this.$message.notification({
            title: "Success",
            text: "Successfully added to review list.",
            type: "success",
            duration: 8000,
          });
        } else {
          this.$message.notification({
            title: "Error",
            text: "Cannot be created.",
            type: "error",
            duration: 8000,
          });
        }
      } catch (e) {
        this.loadings.sendToReview = this.loadings.sendToReview.filter(
          (v) => v !== item.id
        );
        console.error(e);
      }
    },
    tableItemsAdapter(items) {
      return items.map((v) => {
        v.id = uid();
        v.labels_data = v.labels;
        v.labels = v.labels !== null ? JSON.stringify(v.labels) : null;
        return v;
      });
    },
    getLabels(objectOfLabels = {}) {
      if (typeof objectOfLabels !== "object") return [];

      return Object.entries(objectOfLabels);
    },
    async fetchFilters() {
      try {
        const filters = ["executive", "labels"];
        const payload = {
          type: "/worksection/task-priority",
          take: filters,
          filter: {
            projectID: this.$store.state.project.active_project,
          },
        };
        this.loadings.filters = true;
        const resp = await this.$store.dispatch("global/getFilters", payload);
        this.loadings.filters = false;

        if (resp) {
          this.filtersData = { ...this.filtersData, ...resp };
        }
      } catch (e) {
        console.error(e);
        this.loadings.filters = false;
      }
    },
    async fetchData() {
      try {
        const url = "/worksection/task-priority";

        const payload = {
          filter: this.filters,
        };

        this.loadings.table = true;

        const resp = await service.post(url, payload);

        this.loadings.table = false;

        if (resp) {
          this.tableData.items = this.tableItemsAdapter(resp.data.items);
        }
      } catch (e) {
        this.loadings.table = false;
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
.task-priority-dashboard {
  .v-data-table {
    tbody {
      tr {
        td {
          &:nth-last-child(3),
          &:nth-last-child(4),
          &:nth-last-child(5),
          &:nth-last-child(6),
          &:nth-last-child(7),
          &:nth-last-child(8),
          &:nth-last-child(9),
          &:nth-last-child(10) {
            padding: 0 !important;
          }

          &:nth-last-child(2),
          &:nth-last-child(3),
          &:nth-last-child(7),
          &:nth-last-child(10) {
            border-left: 1px solid rgba(155, 155, 155, 0.25);
          }
        }
      }
    }
  }
}
</style>
