<template>
  <v-menu v-model="menu" content-class="shadow-e2-bordered">
    <template #activator="{on}">
      <v-btn
        :color="getBtnColor(value)"
        block
        style="height: 38px;"
        v-on="on"
        dark
      >
        <v-icon v-if="value === 0">
          mdi-pause
        </v-icon>
        <span v-else>{{value}}</span>
      </v-btn>
    </template>
    <v-card class="pa-2" width="300px">
      <div class="priority-select-grid">
        <div style="margin-bottom: 8px;">
          <v-btn
            block
            style="height: 50px"
            :color="getBtnColor(0)"
            @click="value = 0"
            dark
          >
            <v-icon small>
              mdi-pause
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="priority-select-grid">
        <div v-for="item in items" :key="item">
          <v-btn
            block
            :style="{height: '50px'}"
            :color="getBtnColor(item)"
            @click="value = item"
            dark
          >
            <v-icon v-if="item === 0">
              mdi-pause
            </v-icon>
            <span v-else style="font-size: 18px; font-weight: 600;">{{item}}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      default:0
    },
  },
  data: () => ({
    menu: false,
    items: [1,2,3,4,5,6,7,8,9,10]
  }),
  computed: {
    value: {
      get() {return this.modelValue},
      set(value) {this.$emit('update:modelValue', value)}
    }
  },
  methods: {
    getBtnColor(value) {
      const darkTheme = this.$vuetify.theme.dark
      if(value === 0 && darkTheme) return '#566472'
      if(value === 1 && darkTheme) return '#425161'
      if(value === 2 && darkTheme) return '#7d96ad'
      if(value === 3 && darkTheme) return '#659cac'
      if(value === 4 && darkTheme) return '#4f9691'
      if(value === 5 && darkTheme) return '#8ea018'
      if(value === 6 && darkTheme) return '#c0a018'
      if(value === 7 && darkTheme) return '#c08418'
      if(value === 8 && darkTheme) return '#c06318'
      if(value === 9 && darkTheme) return '#c04718'
      if(value === 10 && darkTheme) return '#b72218'
      if(value === 0) return '#5c85b0'
      if(value === 1) return '#4477ad'
      if(value === 2) return '#6cabe3'
      if(value === 3) return '#5bc2e1'
      if(value === 4) return '#52d3c9'
      if(value === 5 ) return '#c0da10'
      if(value === 6 ) return '#ecc20b'
      if(value === 7 ) return '#e59a0b'
      if(value === 8 ) return '#d56810'
      if(value === 9 ) return '#d5440d'
      if(value === 10 ) return '#da1d10'
      return ''
    },
  },
};
</script>

<style lang="scss">
.priority-select-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px
}
</style>