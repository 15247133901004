<template>
  <td class="text-start">
    {{getUrl}}
  </td>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    getUrl() {
      const output =  this.value.replaceAll('https://','').replaceAll('http://').split('/')
      output.shift()
      return '/' + output.join('/')

    }
  }
}
</script>