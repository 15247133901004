<template>
  <div class="anchor-distribution">
    <v-row dense>
      <v-col cols="12">
        <page-name :theme="$vuetify.theme.dark" small="">
          Anchors Distribution
        </page-name>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <div class="custom-bordered-wrapper">
          <v-row dense>
            <v-col cols="1">
              <v-autocomplete
                v-model="filters.gi"
                label="GI"
                disabled
                :items="filtersData.gi"
                item-text="text"
                item-value="value"
                outlined
                multiple
                dense
                clearable
                :loading="loadings.filters"
                @change="_$handleFilterChange($event, 'gi')"
                :menu-props="menuProps"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="filters.do_follow"
                label="Do Follow"
                :items="filtersData.dofollow"
                item-text="text"
                item-value="value"
                outlined
                multiple
                dense
                clearable
                :loading="loadings.filters"
                @change="_$handleFilterChange($event, 'do_follow')"
                :menu-props="menuProps"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filters.link_type"
                label="Links Type"
                :items="filtersData.link_type"
                item-text="text"
                item-value="value"
                outlined
                multiple
                dense
                clearable
                :loading="loadings.filters"
                @change="_$handleFilterChange($event, 'link_type')"
                :menu-props="menuProps"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filters.anchor_type"
                label="Anchor Type"
                :items="filtersData.anchor_type"
                item-text="text"
                item-value="value"
                outlined
                multiple
                dense
                clearable
                :loading="loadings.filters"
                @change="_$handleFilterChange($event, 'anchor_type')"
                :menu-props="menuProps"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col>
              <range-filter
                title="AH DR"
                :model-value="filters.ah_dr"
                :max="100"
                :presets="presets.ahDrPresets"
                @update:modelValue="
                  _$handleRangeChange($event, 'ah_dr', filtersPrefix)
                "
              />
            </v-col>
            <v-col>
              <range-filter
                title="AH UR"
                :model-value="filters.ah_ur"
                :max="100"
                :presets="presets.ahDrPresets"
                @update:modelValue="
                  _$handleRangeChange($event, 'ah_ur', filtersPrefix)
                "
              />
            </v-col>
            <v-col>
              <range-filter
                title="AH Traffic"
                :model-value="filters.ah_traffic"
                :max="1000000"
                @update:modelValue="
                  _$handleRangeChange($event, 'ah_traffic', filtersPrefix)
                "
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="1">
              <v-autocomplete
                v-model="filters.title_thematic"
                label="Title Thematic"
                :items="['yes', 'no']"
                item-text="text"
                item-value="value"
                outlined
                dense
                clearable
                @change="_$handleFilterChange($event, 'title_thematic')"
                :menu-props="menuProps"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="filters.gt"
                label="GT"
                :items="[2, 1, 0]"
                item-text="text"
                item-value="value"
                outlined
                dense
                clearable
                @change="_$handleFilterChange($event, 'gt')"
                :menu-props="menuProps"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col>
              <range-filter
                title="AH URL Traffic"
                :model-value="filters.ah_url_traffic"
                :max="10000"
                :presets="presets.ahUrlTrafficPresets"
                @update:modelValue="
                  _$handleRangeChange($event, 'ah_url_traffic', filtersPrefix)
                "
              />
            </v-col>
            <v-col>
              <range-filter
                title="AH 2nd Level"
                :model-value="filters.ah_2nd_level"
                :max="1000"
                :presets="presets.ah2ndLevelPresets"
                @update:modelValue="
                  _$handleRangeChange($event, 'ah_2nd_level', filtersPrefix)
                "
              />
            </v-col>
            <v-col>
              <range-filter
                title="SW Rank"
                :model-value="filters.sw_rank"
                :max="3000000"
                :presets="presets.swRankPresets"
                @update:modelValue="
                  _$handleRangeChange($event, 'sw_rank', filtersPrefix)
                "
              />
            </v-col>
            <v-col>
              <range-filter
                title="Num of Pages"
                :model-value="filters.nums_of_pages"
                :max="10000"
                :presets="presets.numOfPagesPresets"
                @update:modelValue="
                  _$handleRangeChange($event, 'nums_of_pages', filtersPrefix)
                "
              />
            </v-col>
            <v-col>
              <range-filter
                title="AH Tr RD"
                :model-value="filters.ah_tr_rd"
                :max="10000"
                :presets="presets.numOfPagesPresets"
                @update:modelValue="
                  _$handleRangeChange($event, 'ah_tr_rd', filtersPrefix)
                "
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-autocomplete
                v-model="filters.url"
                label="URL"
                :items="filtersData.url"
                item-text="text"
                item-value="value"
                outlined
                dense
                clearable
                :loading="loadings.filters"
                @change="_$handleFilterChange($event, 'url')"
                :menu-props="menuProps"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="filters.exclude_types"
                multiple
                :items="filtersData.exclude_types"
                dense
                hide-details
                outlined
                label="Exclude types"
                placeholder="Type to search"
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <div class="fix-button-height">
                <smart-date-filter
                  :model-value="filters.date"
                  @update:modelValue="handleUpdateDateFilter"
                  :custom-presets="presets.customDatePresets"
                />
              </div>
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <div class="fix-button-height" style="width: 100%">
                <v-btn
                  block
                  color="primary"
                  @click="handleGetData"
                  :loading="loadings.table"
                  >Get data
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <table-wrapper
          :loading="loadings.table"
          :items="mainTable.items"
          :first-enter="firstEnter"
          :transparent="true"
        >
          <template #default>
            <anchors-distribution-main-table
              :headers="mainTable.headers"
              :items="mainTable.items"
              :selected-url-id="filters.url"
              :filters="filtersData"
            />

            <h2>Top Anchors by Link Types</h2>

            <anchors-distribution-links-table
              :headers="linksTable.headers"
              :items="linksTable.items"
              :selected-url-id="filters.url"
              :filters="filtersData"
            >
            </anchors-distribution-links-table>

            <h2>Top Anchors by Anchor Types</h2>

            <anchors-distribution-links-table
              :headers="anchorsTable.headers"
              :items="anchorsTable.items"
              :selected-url-id="filters.url"
              :filters="filtersData"
            >
            </anchors-distribution-links-table>
          </template>
        </table-wrapper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PageName from "@/components/DashboardDev/PageName";
import {
  AD_FIXED_HEADERS,
  DEFAULT_MENU_PROPS,
  LINKS_DYNAMIC_DASHBOARD_PRESETS,
  PROJECT_ID_IDTF,
} from "@/utils/defaultData";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import RangeFilter from "@/components/PositionsDashboard/Header/RangeFilter";
import TableWrapper from "@/components/UI/TableWrapper";
import AnchorsDistributionMainTable from "@/components/OnePageAnalyzer/AnchorDistribution/AnchorsDistributionMainTable";
import AnchorsDistributionLinksTable from "@/components/OnePageAnalyzer/AnchorDistribution/AnchorsDistributionLinksTable";
import eventPipe from "@/events/eventPipe";
import Location from "../../../mixins/Location";
import Project from "@/mixins/Project";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";

export default {
  components: {
    SmartDateFilter,
    AnchorsDistributionLinksTable,
    AnchorsDistributionMainTable,
    TableWrapper,
    RangeFilter,
    PageName,
  },
  mixins: [FiltersHandling, Location, Project],
  data: () => ({
    loadings: {
      filters: false,
      table: false,
    },
    selectedUrl: null,
    mainTable: {
      headers: [],
      items: [],
    },
    linksTable: {
      headers: [],
      items: [],
    },
    anchorsTable: {
      headers: [],
      items: [],
    },
    filtersData: {
      gi: [],
      dofollow: [],
      link_type: [],
      exclude_types: [],
      url: [],
    },
    filters: {
      gi: [],
      gt: null,
      date: [],
      do_follow: [],
      link_type: [],
      anchor_type: [],
      ah_dr: [0, 0],
      ah_ur: [0, 0],
      ah_traffic: [0, 0],
      ah_url_traffic: [0, 0],
      ah_2nd_level: [0, 0],
      sw_rank: [0, 0],
      nums_of_pages: [0, 0],
      url: null,
      title_thematic: null,
    },
    firstEnter: true,
    filtersPrefix: "filters",
    presets: LINKS_DYNAMIC_DASHBOARD_PRESETS,
    menuProps: DEFAULT_MENU_PROPS,
    headersFixData: AD_FIXED_HEADERS,
  }),
  created() {

    if(this.$route.query[PROJECT_ID_IDTF]) {
      this._$setActiveProject(this.$route.query.project_id, true)
    }

    eventPipe.$on("update-active-project", () => {
      const project_id = this.$store.getters.active_project;
      this._$handleFilterChange(project_id, PROJECT_ID_IDTF);
      this.fetchFilters();
    });

    const filtersWithArrType = [
      "gi",
      "do_follow",
      "link_type",
      "anchor_type",
      "ah_dr",
      "ah_ur",
      "ah_traffic",
      "ah_2nd_level",
      "sw_rank",
      "nums_of_pages",
      "date",
    ];
    const haveParams = this._$collectParams(filtersWithArrType, "filters");
    this.fetchFilters();

    if (!this.$route.query.date) {
      this.handleUpdateDateFilter(this.getDefaultDate);
    }

    if (haveParams) {
      this.fetchData();
    }
  },
  computed: {
    getDefaultDate() {
      const start = this.$moment(new Date())
        .subtract(200, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).format("YYYY-MM-DD");
      return [start, end];
    },
  },
  methods: {
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["url", "link_type", "gi", "anchor_type", "dofollow"];
      const payload = {
        type: "/one-page-analyzer/links-dynamic/main",
        take: filters,
        filter: {
          projectID: this.$store.getters.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    handleGetData() {
      this.fetchData();
    },
    async fetchData() {
      this.loadings.table = true;
      this.firstEnter = false;

      const payload = {
        filter: {
          ...this.filters,
          project_id: this.project_id || this.$store.getters.active_project,
        },
      };

      await this.fetchMainTable(payload);
      this.fetchLinkTypesTable(payload);
      await this.fetchAnchorTypesTable(payload);

      this.loadings.table = false;
    },
    addTooltipToHeader(items) {
      const headersOnFixNames = this.headersFixData.map((v) => v.name);

      return items.map((header) => {
        const headerName = header.text.toLowerCase();
        const match = headersOnFixNames.includes(headerName);

        if (match) {
          const tooltip = this.headersFixData.find(
            (v) => v.name === headerName
          ).tooltip;

          header.tooltip = tooltip;
        }

        return header;
      });
    },
    async fetchMainTable(payload) {
      const resp = await this.$store.dispatch(
        "anchor-distribution/mainTable",
        payload
      );

      if (resp) {
        this.mainTable.items = resp.items;
        this.mainTable.headers = this.addTooltipToHeader(resp.headers);
      }
    },
    async fetchLinkTypesTable(payload) {
      const resp = await this.$store.dispatch(
        "anchor-distribution/linksTable",
        payload
      );

      if (resp) {
        this.linksTable.items = resp.items;
        this.linksTable.headers = resp.headers;
      }
    },
    async fetchAnchorTypesTable(payload) {
      const resp = await this.$store.dispatch(
        "anchor-distribution/anchorsTable",
        payload
      );

      if (resp) {
        this.anchorsTable.items = resp.items;
        this.anchorsTable.headers = resp.headers;
      }
    },
    async wait(ms = 1500) {
      return new Promise((a) => {
        setTimeout(() => {
          return a();
        }, ms);
      });
    },
    handleUpdateDateFilter(value) {
      this.filters.date = value;
      this._$handleFilterChange(value, "date");
    },
  },
};
</script>
