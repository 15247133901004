<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col>
          <h2>Anchors Plan</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="12">
          <v-card
            class="shadow-e2-bordered"
            rounded="xl"
            v-if="isset_active_project"
          >
            <v-card-text>
              <v-btn
                color="success"
                dark
                right
                small
                absolute
                style="margin-right: 60px; margin-top: -43px"
                fab
                @click="upload.modal = true"
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
              <v-menu
                transition="slide-x-transition"
                :close-on-content-click="false"
                min-width="460px"
                max-width="460px"
                content-class="remove-dialog-shadow pa-4"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-fab-transition>
                    <v-btn
                      color="info"
                      small
                      absolute
                      style="margin-right: 10px; margin-top: -43px"
                      right
                      fab
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="31">mdi-filter</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </template>
                <v-card rounded="xl" class="shadow-e2-bordered">
                  <v-card-text>
                    <div class="text-center" style="padding-top: 10px">
                      <h3>Filter</h3>
                    </div>
                    <v-list>
                      <v-list-item>
                        <smart-date-filter
                          :model-value="filter.date_range"
                          @update:modelValue="handleDateChange"
                        />
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          v-model="filter.status"
                          :items="status"
                          item-text="name"
                          item-value="id"
                          label="Status"
                          chips
                          hide-details
                          dense
                          outlined
                          multiple
                          deletable-chips
                          clearable
                        ></v-select>
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          v-model="filter.department"
                          :items="departments"
                          item-text="name"
                          item-value="id"
                          label="Department"
                          chips
                          dense
                          outlined
                          hide-details
                          multiple
                          deletable-chips
                          clearable
                        ></v-select>
                      </v-list-item>
                      <v-list-item class="mt-4">
                        <v-btn block @click="fetch_data" color="primary">
                          Get Data
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-progress-linear
                v-if="loadings.table"
                color="primary"
                indeterminate
                height="6"
              ></v-progress-linear>
              <TableAnchorPlanIndex
                :type="1"
                :loading="loadings.table"
                :status="status"
                :items="table.items"
                :performers="performers"
                @fetch_data="
                  () => {
                    fetch_data();
                  }
                "
              ></TableAnchorPlanIndex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <upload-anchor-plan
      v-if="upload.modal === true"
      v-model="upload.modal"
      level="1"
    ></upload-anchor-plan>
  </div>
</template>

<script>
import eventPipe from "@/events/eventPipe";
import TableAnchorPlanIndex from "@/components/AnchorPlan/Child/TableAnchorPlanIndex";
import UploadAnchorPlan from "@/components/AnchorPlan/Child/UploadAnchorPlan";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import service from "@/plugins/service";
import Notification from "@/mixins/Notification";
import FiltersHandling from "@/mixins/components/FiltersHandling";

export default {
  name: "AnchorPlanIndex",
  components: { SmartDateFilter, TableAnchorPlanIndex, UploadAnchorPlan },
  mixins: [Notification, FiltersHandling],
  metaInfo: {
    title: "Anchor Plan Tasks - LTT - SI",
  },
  data() {
    return {
      loadings: {
        table: false,
      },
      upload: {
        modal: false,
      },
      performers: [],
      date_modal: false,
      status: [],
      departments: [
        { name: "la", id: "la" },
        { name: "ha", id: "ha" },
        { name: "fl", id: "fl" },
      ],
      table: {
        items: [],
      },
      filter: {
        date_range: [],
        status: [1, 2, 3, 4, 6],
        department: [],
      },
    };
  },
  methods: {
    handleDateChange(event) {
      this.filter.date_range = event;
      this._$handleFilterChange(event, "date_range");
    },
    async fetch_data() {
      try {
        if (!this.id_project)
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        const url = `/anchor-plan/tier/tasks/${this.id_project}/1`;
        const payload = {
          filter: this.filter,
        };

        this.loadings.table = true;

        const response = await service.post(url, payload);

        this.table.items = response.data.tasks;
      } catch (e) {
        console.error("Error while loading main data.", e);
      } finally {
        this.loadings.table = false;
      }
    },
    async fetch_status() {
      try {
        this.update_loading("fetch_status", true);

        const url = "/anchor-plan/tasks/list/status";

        const response = await service.post(url, {});

        this.status = response.data;
      } catch (e) {
        console.error("Error while loading status.", e);
      } finally {
        this.update_loading("fetch_status", false);
      }
    },
    async fetch_performers() {
      try {
        this.update_loading("fetch_performers", true);
        const url = "/anchor-plan/tasks/list/performers";

        const response = await service.post(url, {});

        this.performers = response.data;
        this.performers.push({ id: null, name: "not selected" });
      } catch (e) {
        console.error("Error while loading performers data.", e);
      } finally {
        this.update_loading("fetch_performers", false);
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {
    eventPipe.$on("update-active-project", () => {
      this.fetch_data();
    });

    this.fetch_status();
    this.fetch_performers();

    this.fetch_data();
  },
};
</script>

<style scoped></style>
