<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    class="dashboard-dev__signature-table"
  >
    <template v-slot:top>
      <v-text-field
        v-model="search"
        label="Search by Signature"
        prepend-icon="mdi-magnify"
        class="mx-4"
      ></v-text-field>
    </template>

    <template #[`item.idle`]="{ item }">
      <v-chip
        v-if="checkError(item) > 0"
        small
        label
        outlined
        color="error"
        class="signature-table__idle"
      >
        {{ $moment(item.last_success_launched_at).fromNow() }}
      </v-chip>
    </template>
    <template #[`item.last_launch_success`]="{ item }">
      <div class="signature-table__last-succ-launch">
        <div>{{ item.last_success_launched_at }}</div>
      </div>
    </template>
    <template #[`item.status`]="{ item }">
      <div class="signature-table__status">
        <v-icon v-if="checkError(item)" color="red" small
          >mdi-alert-circle-outline</v-icon
        >
        <v-icon v-else color="green" small
          >mdi-checkbox-marked-circle-outline</v-icon
        >
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { PRESETS_TABLE_DATA } from "@/utils/defaultData";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    search: "",
    headers: PRESETS_TABLE_DATA.signatureHeaders,
  }),
  methods: {
    checkError(item) {
      return parseInt(item.last_launched_success) === 0;
    },
  },
};
</script>

<style scoped></style>
