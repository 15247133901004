<template>
  <v-container class="positions" fluid>
    <v-row>
      <v-col>
        <h2>Positions</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="auto" class="d-flex align-end">
        <v-btn
          style="height: 40px"
          block
          class="px-3 text-normal"
          color="primary"
          :loading="loadings.dashboard"
          @click="fetchDashboardData"
        >
          Get data
          <v-icon small right>mdi-refresh</v-icon>
        </v-btn>
        <v-chip v-if="false" outlined label @click="handleCopyUrl">
          {{ copied ? "Copied" : "Copy URL" }}
          <v-icon small right
            >{{ copied ? "mdi-check" : "mdi-content-copy" }}
          </v-icon>
        </v-chip>
      </v-col>
    </v-row>
    <v-card flat outlined rounded="lg" class="px-5 pt-3 pb-5 mt-4 mb-3">
      <v-row dense>
        <v-col cols="12">
          <v-row dense>
            <v-col>
              <filter-label> Project</filter-label>
              <v-autocomplete
                v-model="filters.project"
                :items="filtersData.projects"
                hide-details
                clearable
                dense
                outlined
                :disabled="loadings.projects"
                :loading="loadings.projects"
                placeholder="Type to search"
                append-icon="mdi-chevron-down"
                @change="handleChangeProject"
              />
            </v-col>
            <v-col>
              <filter-label> Subproject</filter-label>
              <v-autocomplete
                v-model="filters.subproject"
                :items="filtersData.subprojects"
                hide-details="auto"
                clearable
                dense
                placeholder="Type to search"
                :disabled="
                  loadings.projects ||
                  (!filters.project && !filtersData.subprojects?.length)
                "
                outlined
                :loading="loadings.subprojects"
                append-icon="mdi-chevron-down"
              />
              <small style="line-height: 110%" class="error--text">
                {{
                  !filters.project ? "To see the list, select a project." : ""
                }}
              </small>
            </v-col>
            <v-col>
              <filter-label> Url</filter-label>
              <v-autocomplete
                v-model="filters.url"
                :items="filtersData.urls"
                hide-details="auto"
                clearable
                dense
                placeholder="Type to search"
                :disabled="
                  loadings.projects ||
                  (!filters.project && !filtersData.urls?.length)
                "
                outlined
                :loading="filtersDataPendingKeys?.urls"
                append-icon="mdi-chevron-down"
              />
              <small style="line-height: 110%" class="error--text">
                {{
                  !filters.project ? "To see the list, select a project." : ""
                }}
              </small>
            </v-col>
            <v-col>
              <filter-label> Cluster</filter-label>
              <v-autocomplete
                v-model="filters.cluster"
                :items="filtersData.clusters"
                hide-details="auto"
                clearable
                dense
                placeholder="Type to search"
                :disabled="
                  loadings.projects ||
                  (!filters.project && !filtersData.urls?.clusters)
                "
                outlined
                :loading="filtersDataPendingKeys?.clusters"
                append-icon="mdi-chevron-down"
              />
              <small style="line-height: 110%" class="error--text">
                {{
                  !filters.project ? "To see the list, select a project." : ""
                }}
              </small>
            </v-col>
            <v-col>
              <filter-label> Priority</filter-label>
              <v-autocomplete
                v-model="filters.priority"
                chips
                deletable-chips
                multiple
                :items="['F', 'S', 'T']"
                hide-details="auto"
                clearable
                dense
                placeholder="Type to search"
                outlined
                append-icon="mdi-chevron-down"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row dense>
            <v-col>
              <filter-label>
                Dropped keywords

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon small v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <template #default>
                    <div style="width: 360px">
                      When enabled, it only shows the keys that had a position
                      of 1-100 yesterday and are in positions 101+ today
                    </div>
                  </template>
                </v-tooltip>
              </filter-label>
              <v-checkbox
                v-model="filters.dropped_keywords"
                hide-details
                dense
                label="Dropped keywords"
              />
            </v-col>
            <v-col>
              <filter-label>
                New keywords

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon small v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <template #default>
                    <div style="width: 360px">
                      When enabled, it only shows the keys that had a position
                      of 101+ yesterday and are in positions 1-100 today
                    </div>
                  </template>
                </v-tooltip>
              </filter-label>
              <v-checkbox
                v-model="filters.new_keywords"
                hide-details
                dense
                label="New keywords"
              />
            </v-col>
            <v-col>
              <filter-label>
                Wrong target URL
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon small v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <template #default>
                    <div style="width: 360px">
                      When enabled, it only shows the keys that are ranked by an
                      irrelevant page from the Keywords control
                    </div>
                  </template>
                </v-tooltip>
              </filter-label>
              <v-checkbox
                v-model="filters.wrong_target_url"
                hide-details
                dense
                label="Wrong target URL"
              />
            </v-col>
            <v-col>
              <filter-label> By top</filter-label>
              <v-autocomplete
                v-model="filters.by_top"
                multiple
                chips
                deletable-chips
                :items="['1', '2-3', '4-10', '11-20', '21-50', '51-100']"
                hide-details="auto"
                clearable
                dense
                placeholder="Type to search"
                outlined
                append-icon="mdi-chevron-down"
              />
            </v-col>
            <v-col>
              <filter-label> Date</filter-label>
              <smart-date-filter
                outlined
                label=""
                :disabled="loadings.projects"
                :model-value="filters.date"
                @update:modelValue="filters.date = $event"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-row v-if="dashboard.trends && dashboard.trends?.length > 0">
      <v-col cols="12">
        <div class="d-flex" style="gap: 0.5rem">
          <trends-card
            v-for="trend in dashboard.trends"
            :key="trend.id"
            :trend="trend"
          />
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="
        dashboard.positions_chart &&
        Object.keys(dashboard.positions_chart).length > 0
      "
    >
      <v-col cols="12" class="pt-0">
        <positions-chart :positions="dashboard.positions_chart" />
      </v-col>
    </v-row>
    <v-row
      v-if="dashboard.positions && Object.keys(dashboard.positions)?.length > 0"
    >
      <v-col cols="12">
        <v-card class="styled-card--default" rounded="lg">
          <v-card-title> Positions data table</v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <positions-data-table :data="dashboard.positions" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        dashboard.page_types && Object.keys(dashboard.page_types)?.length > 0
      "
    >
      <v-col cols="12">
        <v-card class="styled-card--default" rounded="lg">
          <v-card-title>Page type</v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <page-types-table :data="dashboard.page_types" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="dashboard.growth_fell && dashboard.growth_fell?.length > 0"
      dense
    >
      <v-col
        v-for="section in dashboard.growth_fell"
        :key="section.name"
        cols="12"
      >
        <v-card class="styled-card--light">
          <v-card-title>
            {{ section.name }}
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col v-for="table in section.tables" :key="table.name">
                <v-card class="styled-card--default" rounded="lg">
                  <v-card-title>
                    {{ table.name }}
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="pa-0">
                    <growth-fallen-data-table :data="table" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BREADCRUMBS } from "./defaultData";
import GrowthFallenDataTable from "@/components/PositionsV2/GrowthFallenDataTable/index.vue";
import FilterLabel from "@/components/Filter/Label/index.vue";
import Project from "../../../mixins/Project";
import PositionsChart from "../PositionsChart/index.vue";
import TrendsCard from "../TrendsCard/index.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import PositionsDataTable from "../PositionsDataTable/index.vue";
import PageTypesTable from "../PageTypesTable/index.vue";
import { DEFAULT_MOMENT_DATE_FORMAT } from "../../../utils/defaultData";
import PositionsApiService from "../../../services/positions";
import QueryBuilder from "../../../mixins/QueryBuilder";
import copy from "copy-to-clipboard";
import header from "../../Gpm/ViewEntity/Header.vue";

/**
 * @typedef {object} PositionsV2DashboardSections
 * @property {object[]} growth_fell
 * @property {object[]} trends
 * @property {{header: any[], items: any[]}} page_types
 * @property {{header: any[], items: any[]}} positions
 * @property {{categories: any[], series: any[]}} positions_chart
 */

export default {
  components: {
    GrowthFallenDataTable,
    PositionsDataTable,
    FilterLabel,
    SmartDateFilter,
    TrendsCard,
    PositionsChart,
    PageTypesTable,
  },
  mixins: [Project, FiltersHandling, QueryBuilder],
  data: () => ({
    isFiltersBlocked: false,
    copied: false,
    filtersData: {
      subprojects: [],
      projects: [],
      urls: [],
      clusters: [],
      tops: [],
      priority: [],
    },
    breadcrumbs: BREADCRUMBS,
    trends: null, // MOCK_TRENDS_DATA,
    positions: null, //MOCK_POSITIONS,
    positionsTable: null,
    growthFellSections: null,
    pageType: null,
    filtersAlreadyInitialized: false,
    loadings: {
      projects: false,
      dashboard: false,
      subprojects: false,
    },

    /** @type PositionsV2DashboardSections */
    dashboard: {
      growth_fell: [],
      positions_chart: {},
      positions: {},
      page_types: {},
      trends: [],
    },
    filters: {
      project: null,
      by_top: [],
      priority: [],
      dropped_keywords: false,
      new_keywords: false,
      wrong_target_url: false,
      url: null,
      cluster: null,
      subproject: null,
      date: [null, null],
    },
  }),
  watch: {
    "filters.project"(value) {
      if (!value) {
        this.filters.subproject = null;
        this.filtersData.subprojects = [];
      }
    },
  },
  async mounted() {
    this.filtersData = await this.getFilters(["projects"]);

    await this.parseQuery("filters");

    await this.$nextTick();

    if (this.$route.query.project) this.handleChangeProject();

    if (!this.$route.query.date) this.initializeDefaultDateFilter();
  },
  computed: {
    header() {
      return header;
    },
  },
  methods: {
    handleCopyUrl() {
      this.copied = true;

      copy(window.location.href + this._$buildQuery(this.filters));

      setTimeout(() => (this.copied = false), 1000);
    },
    async getSubprojects(projectId) {
      try {
        this.loadings.subprojects = true;
        const filters = ["subprojects"];
        const payload = {
          type: "/serp/positions",
          take: filters,
          filter: {
            projectID: projectId,
          },
        };
        const result = await this.$store.dispatch("global/getFilters", payload);
        return result.subprojects;
      } catch {
        console.error("Error while loading subprojects");
      } finally {
        this.loadings.subprojects = false;
      }
    },
    async getFilters(take, filter) {
      try {
        this.loadings.projects = true;
        const payload = {
          type: "/serp/positions",
          take,
          filter,
        };
        return await this.$store.dispatch("global/getFilters", payload);
      } catch {
        console.error("Error while loading projects");
      } finally {
        this.loadings.projects = false;
      }
    },
    async handleChangeProject() {
      this.filtersData = {
        ...this.filtersData,
        ...(await this.getFilters(
          ["subprojects", "urls", "clusters", "tops", "priority"],
          { projectID: this.filters.project }
        )),
      };

      // Pre-select first subproject
      if (this.filtersData?.subprojects?.length) {
        this.filters.subproject = this.filtersData.subprojects[0]?.value;
      }
    },
    initializeDefaultDateFilter() {
      if (!this.filters.date.every((date) => date)) {
        const start28DaysAgo = this.$moment()
          .subtract(28, "days")
          .startOf("day")
          .format(DEFAULT_MOMENT_DATE_FORMAT);
        const today = this.$moment()
          .endOf("day")
          .format(DEFAULT_MOMENT_DATE_FORMAT);
        console.log(start28DaysAgo, today);

        this.filters.date = [start28DaysAgo, today];
      }
    },
    /**
     * @param data
     * @param data.growth_fell{object[]}
     * @param data.trends{object[]}
     * @param data.page_types{{header: any[], items: any[]}}
     * @param data.positions{{header: any[], items: any[]}}
     * @param data.positions_chart{{categories: any[], series: any[]}}
     */
    initializeDashboard(data) {
      this.dashboard = data;
    },
    async fetchDashboardData() {
      try {
        const positionsApiService = new PositionsApiService();
        this.loadings.dashboard = true;
        const dashboard = await positionsApiService.getV2Dashboard({
          filters: this.filters,
        });
        this.initializeDashboard(dashboard);
      } catch {
        //
      } finally {
        this.loadings.dashboard = false;
      }
    },
  },
};
</script>

<style lang="scss">
.positions {
  //
}
</style>
