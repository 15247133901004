<template>
  <td>
    <v-tooltip bottom>
      <template #activator="{on}">
        <v-progress-circular
            class="ml-5"
            :value="value.value"
            size="50"
            :color="getColor"
            style="font-weight: 600; font-size: 18px"
            v-on="on"
        >
          {{ value.value }}
        </v-progress-circular>
      </template>
      <div>
          <div v-for="(item, i) in value.items" :key="i">
            {{item}}
          </div>
      </div>
    </v-tooltip>
  </td>
</template>

<script>
import {URL_OVERVIEW_COLORS} from "@/utils/defaultData";

export default {
  props: ['value'],
  computed: {
    getColor() {
      const {green, yellow, red} = URL_OVERVIEW_COLORS
      const value = this.value.value
      if (value >= 60 && value <= 100) {
        return green
      }
      if (value >= 1 && value <= 59) {
        return yellow
      }
      if (value === 0) {
        return red
      }

      return 'transparent'
    },
  }
}
</script>