<template>
  <td class="pos-td">
    <v-tooltip bottom>
      <template #activator="{on}">
        <div v-on="on" style="white-space: nowrap" class="main-value d-flex align-center justify-center">
          {{data.value}}
          <sup :style="{color: getSupColor}">{{data.prev === 0 ? '' : data.prev}}</sup>
        </div>
      </template>
      <div>
        {{data.key}}
      </div>
    </v-tooltip>
  </td>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    getSupColor() {
      const value = this.data.prev
      if(value > 0) {
        return 'green'
      }
      if(value < 0) {
        return 'red'
      }
      return 'initial'
    },
  }
}
</script>