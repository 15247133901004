<template>
  <div :class="`time-chart ${is_dark_mode ? 'dark-mode' : ''}`">
    <ApexCharts
      height="275"
      :options="get_chart_options"
      :series="data.series"
      type="rangeBar"
    >
    </ApexCharts>
  </div>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: { ApexCharts },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    get_chart_options() {
      return {
        chart: {
          height: 450,
          type: "rangeBar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "80%",
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        stroke: {
          width: 1,
        },
        fill: {
          type: "solid",
          opacity: 0.6,
        },
        tooltip: {
          custom: (ctx) => {
            let result = "";

            const { w, dataPointIndex } = ctx;

            const items = w.config.series;

            items.forEach((item) => {
              const data = item.data[dataPointIndex];

              if (!data) return;

              result += `<div class="px-4 py-2"><b>${
                item.name
              }:</b> <span>${this.$moment
                .unix(data.y[0])
                .format("HH:mm:ss")}</span> - <span>${this.$moment
                .unix(data.y[1])
                .format("HH:mm:ss")}</span></div>`;
            });

            return result;
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      };
    },
    is_dark_mode() {
      return this.$vuetify.theme.dark;
    },
  },
};
</script>
